import { useContext } from 'react';
import { useAuth } from 'react-oidc-context';

import MenuWrapper from './menu-wrapper';
import MenuItem from '../components/menu-item';
import { Permission } from '../types/permission';
import { LayoutContext } from '../contexts/layout-provider';

/**
 * The menu used for the administration area layouts.
 */
const AdministrationMenu: React.FC<{ classname?: string }> = ({
  classname = ''
}) => {
  const auth = useAuth();
  const userRole = auth.user?.profile?.role as string[];
  const isAdminLanguagesRole = userRole.includes('Admin.Languages') ?? false;
  const isAdminPendingApprovalRole =
    userRole.includes('Admin.PendingApproval') ?? false;

  const isOnlyLanguagesGlobal =
    isAdminLanguagesRole && isAdminPendingApprovalRole ? false : true;

  const layout = useContext(LayoutContext);

  layout.setDrawerMobileMenu(
    <AdministrationMenu classname={'app-side-nav--mobile-drawer'} />
  );

  return (
    <MenuWrapper classname={classname}>
      {/* prettier-ignore */}
      <div className={'aside-menu'}>
        <MenuItem to={'/dashboard'} icon={'chevron_left'} label={'Back to dashboard'} className={'text-success'} />
      </div>
      <hr />
      {/* prettier-ignore */}
      <div className={'aside-menu'}>
        <MenuItem to={''} icon={'admin_panel_settings'} label={'Overview'} />
        <MenuItem to={'users'} icon={'group'} label={'All users'} requiredRoles={[Permission['Admin.Users']]} />
        <MenuItem to={'organisations'} icon={'domain'} label={'Organisations'} requiredRoles={[Permission['Admin.Organisations']]} />
        <MenuItem to={'events/manage'} icon={'calendar_month'} label={'Events'} requiredRoles={[Permission['Admin.Events']]} />
        {!isOnlyLanguagesGlobal && 
          <MenuItem to={'#'} icon={'collections_bookmark'} label={'Global library'} requiredRoles={[Permission['Admin.PendingApproval']]}>
            <MenuItem to={'games/manage'} icon={'collections_bookmark'} label={'Games'} requiredRoles={[Permission['Admin.PendingApproval']]} />
            <MenuItem to={'assessments/manage'} icon={'library_add_check'} label={'Assessments'} requiredRoles={[Permission['Admin.PendingApproval']]} />
            <MenuItem to={'feedbacks/manage'} icon={'insert_chart'} label={'Feedback'} requiredRoles={[Permission['Admin.PendingApproval']]} />
            <MenuItem to={'languages/manage'} icon={'translate'} label={'Languages'} requiredRoles={[Permission['Admin.Languages']]} />
          </MenuItem> 
        }
      {isOnlyLanguagesGlobal && 
          <MenuItem to={'#'} icon={'collections_bookmark'} label={'Global library'} requiredRoles={[Permission['Admin.Languages']]}>
            <MenuItem to={'languages/manage'} icon={'translate'} label={'Languages'} requiredRoles={[Permission['Admin.Languages']]} />
          </MenuItem> 
        }
        <MenuItem to={'#'} icon={'library_add_check'} label={'Pending approval'} requiredRoles={[Permission['Admin.PendingApproval']]}>
          <MenuItem to={'globallibraries/pending'} icon={'library_add_check'} label={'Games'} requiredRoles={[Permission['Admin.PendingApproval']]} />
          <MenuItem to={'assessments/pending'} icon={'library_add_check'} label={'Assessments'} requiredRoles={[Permission['Admin.PendingApproval']]} />
          <MenuItem to={'feedbacks/pending'} icon={'library_add_check'} label={'Feedback'} requiredRoles={[Permission['Admin.PendingApproval']]} />
        </MenuItem>
        <MenuItem to={'environments'} icon={'dns'} label={'Environments'} requiredRoles={[Permission['Admin.Environments']]} />
        <MenuItem to={'support_tickets'} icon={'help_center'} label={'Support Tickets'} requiredRoles={[Permission['Admin.SupportTickets']]} />
        <MenuItem to={'active-events'} icon={'event_available'} label={'Active Events'} requiredRoles={[Permission['Admin.ActiveEvents']]} />
      </div>
    </MenuWrapper>
  );
};

export default AdministrationMenu;
