import { GameDocumentContext } from '../../../contexts/game-document';
import { useContext, useEffect, useState } from 'react';
import Toolbar from '../toolbar';
import {
  DeleteItemAsync,
  UpdateGameDocState,
  UpdateItemAsync,
  UpdateResourceAsync
} from '../../../utils/game-document';
import { useNavigate, useParams } from 'react-router-dom';
import {
  CheckboxChangeEvent,
  InputChangeEvent,
  TextAreaChangeEvent
} from '@progress/kendo-react-inputs';
import { FluidForm } from '../../../components/forms';
import { ItemEntity, ResourceEntity } from '../../../types/game-document/';
import { ItemEditor } from '../../../features/game-document/items';
import cloneDeep from 'lodash.clonedeep';
import RequiredFields from '../../../types/required-fields';
import {
  ERR_DUPLICATE_NAME_VALUE,
  ERR_INPUT_REQUIRED
} from '../../../constants/text';
import { useGameDocumentResources } from '../../../hooks/use-game-document-resources';
import { Col, Row } from 'react-bootstrap';
import { Button } from '@progress/kendo-react-buttons';
import { ActionTopRow } from '../../../components/game-designer/action-top-row';

const Item = () => {
  const { itemId } = useParams();
  const [state, setState] = useContext(GameDocumentContext);
  const navigate = useNavigate();
  const getEntityById = (entityId: string) =>
    state.gameDocument?.assets.items?.find((i) => i.id === entityId)!;

  const [entity, setEntity] = useState<ItemEntity>(getEntityById(itemId!));
  const [resources, setResources] = useState<ResourceEntity[]>(
    [] as ResourceEntity[]
  );
  const [requiredFields, setRequiredFields] = useState<
    RequiredFields<ItemEntity>[]
  >([{ name: 'name', errorMessage: '' }]);

  const gameDocumentFiles = useGameDocumentResources();

  useEffect(() => {
    setEntity(getEntityById(itemId!));
  }, [state]);

  useEffect(() => {
    // Set the page title.
    document.title = `${entity.name} - ${state.gameDocument?.name}`;
    if (entity) {
      const titleRes = state.gameDocument?.resources.find(
        (item) => item.id === entity.titleResId
      )! as ResourceEntity;
      const summaryRes = state.gameDocument?.resources.find(
        (item) => item.id === entity.summaryResId
      )! as ResourceEntity;
      const imageRes = state.gameDocument?.resources.find(
        (item) => item.id === entity.imageResId
      )! as ResourceEntity;
      setResources([titleRes, summaryRes, imageRes]);
    }
  }, [state, entity]);

  const handleInputChange = (event: InputChangeEvent) => {
    const { name, value } = event.target;
    if (name === 'titleResId' || name === 'summaryResId') {
      setResources(
        resources.map((item) =>
          item.id === entity[name]
            ? { ...item, value: value as string }
            : { ...item }
        )
      );
      const resource = resources.find((item) => item.id === entity[name])!;
      resource.value = value as string;
      UpdateResourceAsync(state.gameDocument!, resource?.id, resource).then(
        (updatedGameDocument) => {
          setState((state) => UpdateGameDocState(state, updatedGameDocument));
        }
      );
    } else {
      setRequiredFields((prev) =>
        prev.map((item: RequiredFields<ItemEntity>) => {
          const copyItem = cloneDeep({ ...item });
          copyItem.errorMessage = '';
          if (item.name === (name as keyof ItemEntity)) {
            const duplicateValueIndex: number =
              state.gameDocument?.assets.items
                ?.filter((item: ItemEntity) => item.id !== entity.id)
                .findIndex(
                  (item: ItemEntity) =>
                    item.name.toLowerCase() === (value as string)?.toLowerCase()
                ) ?? -1;
            if ((value as string) === '') {
              copyItem.errorMessage = ERR_INPUT_REQUIRED;
            } else if (duplicateValueIndex !== -1) {
              copyItem.errorMessage = ERR_DUPLICATE_NAME_VALUE;
            }
          }
          return copyItem;
        })
      );
      setEntity((prev) => ({
        ...prev,
        [name!]: value
      }));
      UpdateItemAsync(
        state.gameDocument!,
        itemId!,
        {
          ...entity,
          [name!]: value
        },
        false
      ).then((updatedGameDocument) => {
        setState((state) => UpdateGameDocState(state, updatedGameDocument));
      });
    }
  };

  const handleTextAreaChange = (event: TextAreaChangeEvent) => {
    const { name, value } = event.target;
    setEntity({ ...entity, [name!]: value });

    UpdateItemAsync(
      state.gameDocument!,
      itemId!,
      {
        ...entity,
        [name!]: value
      },
      false
    ).then((updatedGameDocument) => {
      setState((state) => UpdateGameDocState(state, updatedGameDocument));
    });
  };

  const handleImageSelected = (url: string, size: number, name?: string) => {
    gameDocumentFiles.updateResource(
      entity.imageResId!,
      url,
      size,
      name ?? '',
      (resource) => {
        // update the resources in state...
        // TODO: is this even needed? the game document state is updated which would trigger the effect on line 49...
        setResources((current) =>
          current.map((item) =>
            item.id === entity.imageResId ? resource : item
          )
        );
      }
    );
  };

  const handleHideItemChange = (event: CheckboxChangeEvent) => {
    const { name, value } = event.target;
    UpdateItemAsync(
      state.gameDocument!,
      itemId!,
      {
        ...entity,
        [name!]: value
      },
      false
    ).then((updatedGameDocument) => {
      setState((state) => UpdateGameDocState(state, updatedGameDocument));
    });
  };

  const onDeleteEntity = async (entityId: string) => {
    DeleteItemAsync(state.gameDocument!, entityId).then(
      (updatedGameDocument) => {
        setState((state) => UpdateGameDocState(state, updatedGameDocument));
        navigate(-1);
      }
    );
  };

  return (
    <>
      <Toolbar title={entity?.name}></Toolbar>
      <ActionTopRow
        onDeleteClick={() => {
          onDeleteEntity(entity.id!);
        }}
      />
      <FluidForm>
        <ItemEditor
          editorMode={'full'}
          entity={entity}
          resources={resources}
          imageResourceVisibility={true}
          handleInputChange={handleInputChange}
          handleTextAreaChange={handleTextAreaChange}
          handleImageSelected={handleImageSelected}
          requiredFields={requiredFields}
          handleHideItemChange={handleHideItemChange}
        />
      </FluidForm>
    </>
  );
};

export default Item;
