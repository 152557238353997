import React from 'react';
import { useAuth } from 'react-oidc-context';
import { Permission } from '../types/permission';
import { Button } from '@progress/kendo-react-buttons';

interface RoleBasedButtonProps {
  requiredRoles: string[];
  onClick: () => void;
  label: string;
}

const getPermissionKey = (value: string) => {
  const indexOfS = Object.values(Permission).indexOf(
    value as unknown as Permission
  );
  const key = Object.keys(Permission)[indexOfS];
  return key;
};

const RoleBasedButton: React.FC<RoleBasedButtonProps> = ({
  requiredRoles,
  onClick,
  label
}) => {
  const auth = useAuth();
  const roles = requiredRoles?.map((r) => getPermissionKey(r!));
  const isAuthorized = (auth.user?.profile.role as string[]).some((role) =>
    roles?.includes(role)
  );

  return (
    <>
      {isAuthorized && (
        <Button onClick={onClick} themeColor={'primary'}>
          {label}
        </Button>
      )}
    </>
  );
};

export default RoleBasedButton;
