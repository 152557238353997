import { useEffect, useState } from 'react';
import { Button } from '@progress/kendo-react-buttons';
import { Label } from '@progress/kendo-react-labels';
import { ResourceWindow, UploadedImage } from './resource-window';
import { ResourceEntity } from '../../../types/game-document/entities';
import CoverImage from '../../../components/cover-image';

type ImageResourceProps = {
  title?: string;
  imageResource: ResourceEntity;
  onSubmit?: ((url: string, size: number, name: string) => void) | (() => {});
  source?: string;
};

const ImageResource = ({
  title = '',
  imageResource,
  onSubmit = () => {},
  source
}: ImageResourceProps) => {
  const [imageUrl, setImageUrl] = useState<string>('');

  const [entityEditorIsVisible, setEntityEditorIsVisible] =
    useState<boolean>(false);

  const toggleEntityEditor = () => {
    setEntityEditorIsVisible(!entityEditorIsVisible);
  };

  const handleSubmit = (uploadedImage: UploadedImage) => {
    setImageUrl(uploadedImage.blobUrl);
    onSubmit(
      uploadedImage.blobUrl,
      uploadedImage.size,
      uploadedImage?.fileName ?? ''
    );
    setEntityEditorIsVisible(false);
  };

  useEffect(() => {
    if (imageResource) setImageUrl(imageResource.value!);
  }, [imageResource]);

  return (
    <>
      <div className={'d-flex mt-2 mb-2 w-full flex-column'}>
        <Label>{title}</Label>

        <div className={'position-relative'}>
          <CoverImage
            imageUrl={imageUrl}
            containerStyle={'rounded'}
            className={'mr-2 mb-3'}
            style={{ flex: 1 }}
            isfullWidth={true}
            onDropFiles={handleSubmit}>
            <Button
              className={'position-absolute  mt-1 translate-middle '}
              fillMode={'flat'}
              style={{ left: '-0.9rem' }}
              onClick={toggleEntityEditor}>
              <span className={'material-symbols-outlined'}>more_vert</span>
            </Button>
          </CoverImage>
        </div>
        {entityEditorIsVisible && (
          <ResourceWindow
            toggleDialog={toggleEntityEditor}
            onSubmit={handleSubmit}
            imageUrl={imageUrl}
            imageSource={
              source === 'Inventory'
                ? 'Game Design | Assets - Inventory'
                : source === 'Title'
                  ? 'Game Design | Assets - Title'
                  : undefined
            }
          />
        )}
      </div>
    </>
  );
};

export default ImageResource;
