import { GameDocumentContext } from '../../contexts/game-document';
import { matchPath, Outlet, useLocation, useParams } from 'react-router-dom';
import { useContext } from 'react';
import Header, { HeaderBreadcrumb } from '../../components/header';
import {
  GetAreaById,
  GetAreasByIds,
  GetMapById,
  GetResourceValue,
  GetTaskById,
  GetTaskContentById,
  GetZoneById
} from '../../utils/game-document';

interface PageProps {
  className?: string;
}

type Params = {
  zoneId?: string;
  taskId?: string;
  taskContentId?: string;
  timerId?: string;
  titleId?: string;
  mapId?: string;
  areaId?: string;
  itemId?: string;
};

const Page = (props: PageProps) => {
  const [state, setState] = useContext(GameDocumentContext);
  const location = useLocation();
  const {
    zoneId,
    taskId,
    taskContentId,
    timerId,
    titleId,
    mapId,
    areaId,
    itemId
  } = useParams<Params>();

  // HACK: This Is Horrible
  const getCrumbs = () => {
    let items = Array<HeaderBreadcrumb>();
    let match = matchPath('/designer/:gameId', location.pathname);
    if (match) {
      items.push({ text: 'Overview', to: match.pathnameBase });
    }
    if ((match = matchPath('/designer/:gameId/map/*', location.pathname))) {
      items.push({ text: 'Map', to: match.pathnameBase });
    }

    if ((match = matchPath('/designer/:gameId/assets/*', location.pathname))) {
      items.push({ text: 'Assets', to: match.pathnameBase });
    }

    if (
      (match = matchPath(
        '/designer/:gameId/assets/task-content/*',
        location.pathname
      ))
    ) {
      items.push({ text: 'Task Content', to: match.pathnameBase });
    }
    if (
      (match = matchPath(
        '/designer/:gameId/assets/task-content/:taskId/*',
        location.pathname
      ))
    ) {
      items.push({
        text: getTaskContentTitle(taskContentId),
        to: match.pathnameBase
      });
    }
    if (
      (match = matchPath('/designer/:gameId/assets/tasks/*', location.pathname))
    ) {
      items.push({ text: 'Tasks', to: match.pathnameBase });
    }
    if (
      (match = matchPath(
        '/designer/:gameId/assets/tasks/:taskId/*',
        location.pathname
      ))
    ) {
      items.push({ text: getTaskTitle(taskId), to: match.pathnameBase });
    }
    if (
      (match = matchPath(
        '/designer/:gameId/assets/titles/*',
        location.pathname
      ))
    ) {
      items.push({ text: 'Title', to: match.pathnameBase });
    }
    if (
      (match = matchPath(
        '/designer/:gameId/assets/titles/:titleId/*',
        location.pathname
      ))
    ) {
      items.push({ text: getTitleName(titleId), to: match.pathnameBase });
    }
    if (
      (match = matchPath(
        '/designer/:gameId/assets/inventory/*',
        location.pathname
      ))
    ) {
      items.push({ text: 'Inventory', to: match.pathnameBase });
    }
    if (
      (match = matchPath(
        '/designer/:gameId/assets/inventory/:itemId/*',
        location.pathname
      ))
    ) {
      items.push({ text: getItemName(itemId), to: match.pathnameBase });
    }
    if (
      (match = matchPath(
        '/designer/:gameId/assets/timers/*',
        location.pathname
      ))
    ) {
      items.push({ text: 'Timers', to: match.pathnameBase });
    }
    if (
      (match = matchPath(
        '/designer/:gameId/assets/timers/:timerId/*',
        location.pathname
      ))
    ) {
      items.push({ text: getTimerName(timerId), to: match.pathnameBase });
    }
    if (
      (match = matchPath('/designer/:gameId/assets/maps/*', location.pathname))
    ) {
      items.push({ text: 'Maps', to: match.pathnameBase });
    }
    if (
      (match = matchPath(
        '/designer/:gameId/assets/maps/:mapId/*',
        location.pathname
      ))
    ) {
      items.push({ text: getMapName(mapId), to: match.pathnameBase });
    }

    if (
      (match = matchPath('/designer/:gameId/assets/zones/*', location.pathname))
    ) {
      items.push({ text: 'Zones', to: match.pathnameBase });
    }
    if (
      (match = matchPath(
        '/designer/:gameId/assets/zones/:zoneId/*',
        location.pathname
      ))
    ) {
      items.push({ text: getZoneTitle(zoneId), to: match.pathnameBase });
    }
    if (
      (match = matchPath('/designer/:gameId/assets/areas/*', location.pathname))
    ) {
      items.push({ text: 'Areas', to: match.pathnameBase });
    }
    if (
      (match = matchPath(
        '/designer/:gameId/assets/areas/:areaId/*',
        location.pathname
      ))
    ) {
      items.push({ text: getAreaName(areaId), to: match.pathnameBase });
    }

    if (
      (match = matchPath(
        '/designer/:gameId/resources/resource/*',
        location.pathname
      ))
    ) {
      items.push({ text: 'Resource', to: match.pathnameBase });
    }

    if (
      (match = matchPath(
        '/designer/:gameId/resources/theme/*',
        location.pathname
      ))
    ) {
      items.push({ text: 'Theme', to: match.pathnameBase });
    }

    if (
      (match = matchPath(
        '/designer/:gameId/resources/language/*',
        location.pathname
      ))
    ) {
      items.push({ text: 'Language', to: match.pathnameBase });
    }

    if (
      (match = matchPath('/designer/:gameId/settings/*', location.pathname))
    ) {
      items.push({ text: 'Settings', to: match.pathnameBase });
    }

    if ((match = matchPath('/designer/:gameId/sales/*', location.pathname))) {
      items.push({ text: 'Sales & Marketing', to: match.pathnameBase });
    }

    if (
      (match = matchPath('/designer/:gameId/diagnostics/*', location.pathname))
    ) {
      items.push({ text: 'Diagnostics', to: match.pathnameBase });
    }

    if (
      (match = matchPath('/designer/:gameId/map/:zoneId/*', location.pathname))
    ) {
      items.push({ text: `${getZoneTitle(zoneId)}`, to: match.pathnameBase });
    }
    if ((match = matchPath('/designer/:gameId/items/*', location.pathname))) {
      items.push({ text: 'Inventory', to: match.pathnameBase });
    }
    if ((match = matchPath('/designer/:gameId/titles/*', location.pathname))) {
      items.push({ text: 'Titles', to: match.pathnameBase });
    }
    if (
      (match = matchPath('/designer/:gameId/assessment/*', location.pathname))
    ) {
      items.push({ text: 'Assessment', to: match.pathnameBase });
    }
    return items;
  };
  const getResourceValue = (resourceId: string | undefined) => {
    if (resourceId && resourceId !== '') {
      return GetResourceValue(state.gameDocument!, resourceId!);
    } else {
      return 'New Game';
    }
  };
  const getZoneTitle = (zoneId: string | undefined) => {
    if (zoneId) {
      const zone = GetZoneById(state.gameDocument, zoneId);
      if (zone) {
        const title = GetResourceValue(state.gameDocument!, zone.titleResId!);
        return title === '' ? zone.name : title;
      }
    }
  };

  const getTitleById = (titleId: string) =>
    state.gameDocument?.assets.titles?.find((i) => i.id === titleId)!;

  const getTimerById = (timerId: string) =>
    state.gameDocument?.assets.timers?.find((i) => i.id === timerId)!;

  const getItemById = (itemId: string) =>
    state.gameDocument?.assets.items?.find((i) => i.id === itemId)!;

  const getTaskTitle = (taskId: string | undefined) => {
    if (taskId) {
      const task = GetTaskById(state.gameDocument!, taskId);
      if (task) {
        return task.name!;
      }
    }
  };

  const getTaskContentTitle = (taskContentId: string | undefined) => {
    if (taskContentId) {
      const taskContent = GetTaskContentById(
        state.gameDocument!,
        taskContentId
      );
      if (taskContent) {
        return taskContent.name!;
      }
    }
  };

  const getTitleName = (titleId: string | undefined) => {
    if (titleId) {
      const title = getTitleById(titleId);
      if (title) {
        return title.name!;
      }
    }
  };

  const getTimerName = (timerId: string | undefined) => {
    if (timerId) {
      const title = getTimerById(timerId);
      if (title) {
        return title.name!;
      }
    }
  };

  const getMapName = (mapId: string | undefined) => {
    if (mapId && state.gameDocument) {
      const map = GetMapById(state.gameDocument, mapId);
      if (map) {
        return map.name!;
      }
    }
  };

  const getAreaName = (areaId: string | undefined) => {
    if (areaId && state.gameDocument) {
      const area = GetAreaById(state.gameDocument, areaId);
      if (area) {
        return area.name!;
      }
    }
  };

  const getItemName = (itemId: string | undefined) => {
    if (itemId) {
      const item = getItemById(itemId);
      if (item) {
        return item.name!;
      }
    }
  };

  return (
    <div className={`page-base ${props.className ?? 'page-base--full'}`}>
      <Header
        title={state.gameDocument?.name ?? ''}
        breadCrumbs={getCrumbs()}
      />
      <main>
        <Outlet />
      </main>
    </div>
  );
};

export default Page;
