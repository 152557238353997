import { TimePicker } from '@progress/kendo-react-dateinputs';
import { ComboBoxChangeEvent } from '@progress/kendo-react-dropdowns';
import { TextArea } from '@progress/kendo-react-inputs';
import { Label } from '@progress/kendo-react-labels';
import { TaskContentType } from '../../features/game-document/task-contents/task-content-full-editor';
import {
  AnswerType,
  TaskContentAnswer
} from '../../types/game-document/entities/task-content';
import { getTime, getTimePickerFormat } from '../../utils/date';
import { ComboboxInput, FormInput } from '../form-input';
import { AnswerCheckbox } from './answer-checkbox';
import { AnswerOptions } from './answer-options';
import {
  OverlayTrigger,
  Popover,
  PopoverBody,
  PopoverHeader,
  Tooltip
} from 'react-bootstrap';
import { useState } from 'react';

export interface AnswerProps {
  id?: string;
  answerType?: AnswerType;
  answers?: TaskContentAnswer[];
  onChangeAnswerType?: (index: number, type: AnswerType) => void;
  onAddAnswer?: (index: number) => void;
  onDeleteOption?: (index: number, optionIndex: number) => void;
  onChangeAnswers?: (answers: TaskContentAnswer[]) => void;
  handleInputChange?: (
    value: string,
    type: 'question' | 'answer',
    answerResId?: string
  ) => void;
}
export function Answer({
  id = '',
  answerType,
  answers,
  onChangeAnswerType = () => {},
  onAddAnswer = () => {},
  onDeleteOption = () => {},
  onChangeAnswers = () => {},
  handleInputChange = () => {}
}: AnswerProps) {
  const [showShortAnswerTooltip, setShowShortAnswerTooltip] = useState(false);
  const toggleTooltip = () => setShowShortAnswerTooltip((prev) => !prev);

  const contentTypes = [
    { id: 'radio', description: 'Multi choice' },
    { id: 'checkbox', description: 'Checkboxes' },
    { id: 'text', description: 'Short answer' },
    { id: 'textarea', description: 'Paragraph' },
    { id: 'number', description: 'Number' },
    { id: 'time', description: 'Time' },
    { id: 'file', description: 'File' },
    { id: 'image', description: 'Image' },
    { id: 'video', description: 'Video' },
    { id: 'none', description: 'No input - Manual task' }
  ];

  const onChangeAnswerTypeHandler = (e: ComboBoxChangeEvent) => {
    if (id !== '') {
      onChangeAnswerType(parseInt(id), (e.target.value as TaskContentType).id!);
    }
  };

  const onAddOptionHandler = () => {
    if (id !== '') {
      onAddAnswer(parseInt(id));
    }
  };

  const onDeleteOptionHandler = (index: number) => {
    if (id !== '') {
      onDeleteOption(parseInt(id), index);
    }
  };

  const onChangeAnswerHandler = (answers: TaskContentAnswer[]) => {
    onChangeAnswers(answers);
  };

  return (
    <>
      <ComboboxInput
        label={'Answer type'}
        value={contentTypes.find((x) => x.id === answerType)}
        onChange={(e) => {
          onChangeAnswerTypeHandler(e);
        }}
        textField={'description'}
        data={contentTypes}
        clearButton={false}
      />

      {answerType === 'radio' && (
        <AnswerOptions
          group={id}
          onDeleteOption={onDeleteOptionHandler}
          options={answers}
          onChangeAnswers={onChangeAnswerHandler}
          onAddOption={onAddOptionHandler}
          handleInputChange={handleInputChange}
        />
      )}

      {answerType === 'checkbox' && (
        <AnswerCheckbox
          group={id}
          onDeleteOption={onDeleteOptionHandler}
          options={answers}
          onChangeAnswers={onChangeAnswerHandler}
          onAddOption={onAddOptionHandler}
          handleInputChange={handleInputChange}
        />
      )}

      {answerType === 'text' && (
        <>
          <Label className={'mt-2'}>Acceptable answer / pattern</Label>
          <OverlayTrigger
            placement={'bottom-start'}
            show={showShortAnswerTooltip}
            overlay={
              <Popover className={'popover-short-answer'}>
                <PopoverBody
                  className={'d-flex flex-column '}
                  style={{
                    fontSize: '12px',
                    color: 'black'
                  }}>
                  <span>
                    <b>Example:</b> .*answer.*
                  </span>
                  <span>
                    <b>
                      For multiple answers, separate them with |. for example:{' '}
                    </b>
                    .*square.*|.*box.*
                  </span>
                </PopoverBody>
              </Popover>
            }>
            <div>
              <FormInput
                name={'answerResId'}
                placeholder={'.*example.*'}
                className={'mt-2'}
                onChange={(e) =>
                  handleInputChange(
                    e.target.value as string,
                    'answer',
                    answers![0].answerResId
                  )
                }
                value={answers![0].description}
                autoComplete={'off'}
                onFocus={toggleTooltip}
                onBlur={toggleTooltip}
              />
            </div>
          </OverlayTrigger>
        </>
      )}

      {answerType === 'number' && (
        <FormInput
          name={'answerResId'}
          type={'number'}
          className={'mt-2'}
          onKeyDown={(e) => {
            const regex = /^[0-9.]$/;
            const value = e.currentTarget.value as string;

            const navigationKeys = [
              'Backspace',
              'Delete',
              'ArrowLeft',
              'ArrowRight',
              'Tab'
            ];
            if (navigationKeys.includes(e.key)) {
              return;
            }

            if (e.key === '.' && value.includes('.')) {
              e.preventDefault();
              return;
            }

            if (!regex.test(e.key)) {
              e.preventDefault();
            }
          }}
          onChange={(e) => {
            handleInputChange(
              e.target.value as string,
              'answer',
              answers![0].answerResId
            );
          }}
          value={answers![0].description}
        />
      )}

      {answerType === 'time' && (
        <TimePicker
          name={'answerResId'}
          className={'mt-2'}
          value={getTimePickerFormat(answers![0].description)}
          onChange={(e) =>
            handleInputChange(
              getTime(e.target.value!),
              'answer',
              answers![0].answerResId
            )
          }
        />
      )}

      {answerType === 'textarea' && (
        <TextArea
          name={'answerResId'}
          className={'mt-2'}
          rows={4}
          value={answers![0].description}
          onChange={(e) =>
            handleInputChange(
              e.target.value as string,
              'answer',
              answers![0].answerResId
            )
          }
        />
      )}
    </>
  );
}
