import { Checkbox, CheckboxChangeEvent } from '@progress/kendo-react-inputs';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Permission, PermissionType } from '../types/permission';

interface UserPermissionProps {
  permissions: PermissionType[];
  userPermissions: PermissionType[];
  showAdminPermission: boolean;
  onChange: (permission: PermissionType, value: boolean) => void;
}

const UserPermission = (props: UserPermissionProps) => {
  const defaultPermission = Object.keys(Permission).filter(
    (permission) => permission.split('.')[0] !== Permission.Admin
  ) as PermissionType[];

  const childPermission = props.permissions.filter(
    (permission, index) =>
      permission.split('.')[0] === Permission.Admin && index > 0
  );

  const childPendingApprovalRole = (
    Object.keys(Permission) as PermissionType[]
  ).filter(
    (permission, index) =>
      permission.split('.').length > 2 &&
      permission.split('.')[1] === 'PendingApproval' &&
      index > 0
  );

  const permissionKeyList = Object.keys(Permission) as PermissionType[];

  const childAdminRoles = (Object.keys(Permission) as PermissionType[]).filter(
    (permission, index) =>
      permission.split('.').length > 1 &&
      permission.split('.').length < 3 &&
      permission.split('.')[0] === Permission.Admin &&
      index > 0
  );

  const applicationPermission = (
    Object.keys(Permission) as PermissionType[]
  ).filter(
    (permission, index) => permission.split('.')[0] === 'App' && index > 0
  );

  const setRoleToDisable = (permission?: string) => {
    let result = props.permissions.find((role) => role.includes(permission!));
    if (result) return false;
    return true;
  };

  const OnChangeHandler = (e: CheckboxChangeEvent) => {
    props.onChange(e.target.name as PermissionType, e.value);
  };

  return (
    <div className="ml-10">
      {permissionKeyList
        .filter(
          (fp) =>
            fp.split('.')[0] !== Permission.Admin && fp.split('.')[0] !== 'App'
        )
        .map((permission, index) => {
          const marginLeft = permission.split('.').length > 1 ? 'ml-5' : '';
          const marginTop = index > 0 ? 'mt-2' : '';
          return (
            <Row className={`${marginTop} ${marginLeft}`} key={index}>
              <Col size={'md-4'}>
                <Checkbox
                  name={permission}
                  label={Permission[permission]}
                  size={'large'}
                  value={props.userPermissions?.includes(permission)}
                  onChange={OnChangeHandler}
                  disabled={setRoleToDisable(permission)}
                />
              </Col>
            </Row>
          );
        })}

      {props.showAdminPermission && (
        <>
          {/* Admin role */}
          <Row className={'mt-2'}>
            <Col size={'md-4'}>
              <Checkbox
                name={Permission.Admin}
                label={Permission.Admin}
                size={'large'}
                value={props.userPermissions?.includes(Permission.Admin)}
                onChange={OnChangeHandler}
                disabled={setRoleToDisable(Permission.Admin)}
              />
            </Col>
          </Row>

          {/* Admin - Child Roles */}
          {childAdminRoles
            .filter(
              (child) =>
                child !== 'Admin.SupportTickets' &&
                child !== 'Admin.ActiveEvents'
            )
            .map((permission, index) => (
              <Row className={'mt-2 ml-5'} key={`child_${index}`}>
                <Col size={'md-4'}>
                  <Checkbox
                    name={permission}
                    label={Permission[permission]}
                    size={'large'}
                    value={props.userPermissions?.includes(permission)}
                    onChange={OnChangeHandler}
                    disabled={setRoleToDisable(permission)}
                  />
                </Col>
              </Row>
            ))}

          {/* Admin - Child Roles */}
          {childPendingApprovalRole.map((permission, index) => (
            <Row className={'mt-2 ml-5'} key={`child_child_${index}`}>
              <Col size={'md-4'}>
                <Checkbox
                  name={permission}
                  label={Permission[permission]}
                  size={'large'}
                  value={props.userPermissions?.includes(permission)}
                  onChange={OnChangeHandler}
                  disabled={setRoleToDisable(permission)}
                />
              </Col>
            </Row>
          ))}

          {childAdminRoles
            .filter((child) => child === 'Admin.SupportTickets')
            .map((permission, index) => (
              <Row className={'mt-2 ml-5'} key={`child_${index}`}>
                <Col size={'md-4'}>
                  <Checkbox
                    name={permission}
                    label={Permission[permission]}
                    size={'large'}
                    value={props.userPermissions?.includes(permission)}
                    onChange={OnChangeHandler}
                    disabled={setRoleToDisable(permission)}
                  />
                </Col>
              </Row>
            ))}

          {childAdminRoles
            .filter((child) => child === 'Admin.ActiveEvents')
            .map((permission, index) => (
              <Row className={'mt-2 ml-5'} key={`child_${index}`}>
                <Col size={'md-4'}>
                  <Checkbox
                    name={permission}
                    label={Permission[permission]}
                    size={'large'}
                    value={props.userPermissions?.includes(permission)}
                    onChange={OnChangeHandler}
                    disabled={setRoleToDisable(permission)}
                  />
                </Col>
              </Row>
            ))}

          {/* Application role */}
          {applicationPermission.map((permission) => (
            <Row className={'mt-2'}>
              <Col size={'md-4'}>
                <Checkbox
                  name={permission}
                  label={Permission[permission]}
                  size={'large'}
                  value={props.userPermissions?.includes(permission)}
                  onChange={OnChangeHandler}
                  disabled={setRoleToDisable(permission)}
                />
              </Col>
            </Row>
          ))}
        </>
      )}
    </div>
  );
};

export default UserPermission;
