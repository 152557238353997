import { useContext, useState } from 'react';
import {
  CheckboxChangeEvent,
  InputChangeEvent,
  TextAreaChangeEvent
} from '@progress/kendo-react-inputs';
import { Button } from '@progress/kendo-react-buttons';
import { EntityEditor } from '../../../types/game-document/entity-editor';
import { GameDocumentContext } from '../../../contexts/game-document';
import RequiredFields from '../../../types/required-fields';
import {
  ERR_DUPLICATE_NAME_VALUE,
  ERR_INPUT_REQUIRED
} from '../../../constants/text';
import { Typography } from '@progress/kendo-react-common';
import { ItemEditor } from '../../../features/game-document/items';
import Toolbar from '../toolbar';
import {
  AddItemAsync,
  AddResourceAsync,
  UpdateGameDocState
} from '../../../utils/game-document';
import { uuid } from '../../../types/common-helper';
import { useNavigate } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import { ItemEntity, ResourceEntity } from '../../../types/game-document/';

export const NewItem = () => {
  const titleGuid = uuid();
  const summaryGuid = uuid();
  const imageGuid = uuid();
  const titleResource: ResourceEntity = {
    id: titleGuid,
    name: 'Title',
    description: '',
    type: 'text',
    value: ''
  };

  const summaryResource: ResourceEntity = {
    id: summaryGuid,
    name: 'Summary',
    description: '',
    type: 'text',
    value: ''
  };

  const imageResource: ResourceEntity = {
    id: imageGuid,
    name: imageGuid,
    description: '',
    type: 'image',
    value: '',
    size: 0
  };

  const [state, setState] = useContext(GameDocumentContext);
  const [entity, setEntity] = useState<ItemEntity>({
    titleResId: titleGuid,
    imageResId: imageGuid,
    description: '',
    name: '',
    hideInGame: false,
    summaryResId: summaryGuid,
    id: ''
  });
  const [resources, setResources] = useState<ResourceEntity[]>([
    titleResource,
    summaryResource,
    imageResource
  ]);

  const [requiredFields, setRequiredFields] = useState<
    RequiredFields<ItemEntity>[]
  >([{ name: 'name', errorMessage: '' }]);
  const navigate = useNavigate();

  const handleInputChange = (event: InputChangeEvent) => {
    const { name, value } = event.target;
    if (name === 'titleResId' || name === 'summaryResId') {
      const key = entity[name]!;
      setResources(
        resources.map((item) =>
          item.id === key ? { ...item, value: value! as string } : { ...item }
        )
      );
    } else {
      setEntity({ ...entity, [name!]: value });
    }
  };

  const handleTextAreaChange = (event: TextAreaChangeEvent) => {
    const { name, value } = event.target;
    setEntity({ ...entity, [name!]: value });
  };

  const handleImageSelected = (url: string, size: number, name?: string) => {
    setResources(
      resources.map((item) =>
        item.id === entity.imageResId
          ? {
              ...item,
              value: url,
              size: size,
              name: item.id,
              description: name ?? ''
            }
          : { ...item }
      )
    );
  };

  const isInputValid = (): boolean => {
    const inputValidation = requiredFields.map((input) => {
      const entityValue = entity[input.name as keyof ItemEntity];
      const itemIndex = state.gameDocument?.assets?.items?.findIndex(
        (item) =>
          typeof item.name === 'string' &&
          typeof entityValue === 'string' &&
          item.name.toLowerCase() === entityValue?.toLowerCase()
      );

      if (input.name === 'name') {
        // Check validation for input name
        if (entityValue === '') {
          input.errorMessage = ERR_INPUT_REQUIRED;
        } else if (
          itemIndex !== -1 &&
          state.gameDocument?.assets?.items![itemIndex!]?.id !== entity.id
        ) {
          input.errorMessage = ERR_DUPLICATE_NAME_VALUE;
        } else {
          input.errorMessage = '';
        }
      }

      return input;
    });
    setRequiredFields(inputValidation);
    return (
      inputValidation.findIndex((input) => input.errorMessage !== '') === -1
    );
  };

  const handleHideItemChange = (event: CheckboxChangeEvent) => {
    const { name, value } = event.target;
    setEntity({ ...entity, [name!]: value });
  };

  const handleEntityEditorSubmit = (
    editorEntity: EntityEditor<ItemEntity>,
    resourceEntity: EntityEditor<ResourceEntity>[]
  ) => {
    const addTitleResource = AddResourceAsync(
      state.gameDocument!,
      'Title',
      '',
      'text',
      resourceEntity.find(
        (item) => item.entity.id === editorEntity.entity.titleResId!
      )?.entity.value!,
      titleGuid
    );
    const addSummaryResource = AddResourceAsync(
      state.gameDocument!,
      'Summary',
      '',
      'text',
      resourceEntity.find(
        (item) => item.entity.id === editorEntity.entity.summaryResId!
      )?.entity.value!,
      summaryGuid
    );

    const imageResource = resourceEntity.find(
      (item) => item.entity.id === editorEntity.entity.imageResId!
    )?.entity;
    const addImageResource = AddResourceAsync(
      state.gameDocument!,
      imageGuid,
      imageResource?.description ?? '',
      'image',
      imageResource?.value ?? '',
      imageGuid,
      imageResource?.size ?? 0
    );

    Promise.all([addTitleResource, addSummaryResource, addImageResource]).then(
      () => {
        const {
          entity: { name, description }
        } = editorEntity;
        AddItemAsync(
          state.gameDocument!,
          name,
          description,
          titleGuid,
          summaryGuid,
          imageGuid,
          editorEntity.entity.hideInGame
        ).then((updatedGameDocument) => {
          setState((prev) => UpdateGameDocState(prev, updatedGameDocument));
          navigate(-1);
        });
      }
    );
  };

  return (
    <>
      <Toolbar title={'New Inventory'} showDefaultContent={false}>
        <div className={'d-flex ml-2 gap-2 pl-3'}>
          <Button
            themeColor={'secondary'}
            onClick={() => {
              navigate(-1);
            }}>
            Back
          </Button>
          <Button
            onClick={() => {
              if (isInputValid()) {
                const entityResources = resources.map((item, index) => ({
                  isNew: true,
                  entity: item
                })) as EntityEditor<ResourceEntity>[];
                handleEntityEditorSubmit(
                  { isNew: true, entity },
                  entityResources
                );
              }
            }}
            themeColor={'primary'}
            fillMode={'solid'}>
            Create
          </Button>
        </div>
      </Toolbar>

      <ItemEditor
        editorMode={'full'}
        entity={entity}
        resources={resources}
        handleInputChange={handleInputChange}
        handleTextAreaChange={handleTextAreaChange}
        handleImageSelected={handleImageSelected}
        requiredFields={requiredFields}
        handleHideItemChange={handleHideItemChange}>
        <div className={'mt-1'}>
          <Typography.p fontSize={'xsmall'}>
            Notes: Please use images with a 1:1 aspect ratio to achieve a better
            experience on the Player Screen.
          </Typography.p>
        </div>
      </ItemEditor>
    </>
  );
};
