import { StandardInput } from '../../../components/forms';
import { ComponentType, ReactNode } from 'react';
import { TimerEntity, ResourceEntity } from '../../../types/game-document/';
import {
  CheckboxChangeEvent,
  InputChangeEvent,
  TextAreaChangeEvent
} from '@progress/kendo-react-inputs';
import { EditorMode } from '../../../types/editor-mode';
import RequiredFields from '../../../types/required-fields';
import { TextareaInput } from '../../../components/form-input';

interface TimerEditorProps {
  editorMode: EditorMode;
  entity: TimerEntity;
  resources: ResourceEntity[];
  handleInputChange: (event: InputChangeEvent | CheckboxChangeEvent) => void;
  children?: ReactNode | undefined;
  requiredFields?: RequiredFields<TimerEntity>[];
  handleTextAreaChange: (event: TextAreaChangeEvent) => void;
}

export const TimerEditor: ComponentType<TimerEditorProps> = ({
  editorMode,
  entity,
  resources,
  handleInputChange,
  requiredFields,
  handleTextAreaChange,
  ...props
}: TimerEditorProps) => {
  return (
    <>
      <StandardInput
        name={'name'}
        label={'Name'}
        value={entity?.name}
        onChange={handleInputChange}
        validationMessage={
          requiredFields?.find((item) => item.name === 'name')?.errorMessage
        }
        autoFocus={true}
      />
      <TextareaInput
        label={'Description'}
        name={'description'}
        value={entity?.description}
        rows={4}
        onChange={handleTextAreaChange}></TextareaInput>

      <StandardInput
        name={'titleResId'}
        label={'Title'}
        value={
          resources.find((item) => item?.id === entity?.titleResId)?.value ?? ''
        }
        validationMessage={
          requiredFields?.find((item) => item.name === 'titleResId')
            ?.errorMessage
        }
        onChange={handleInputChange}
      />
      {props.children}
    </>
  );
};
