import { useContext, useEffect, useState } from 'react';
import { GameDocumentContext } from '../../../contexts/game-document';
import Toolbar from '../toolbar';
import {
  DeleteAreaAsync,
  UpdateGameDocState,
  UpdateAreaAsync
} from '../../../utils/game-document';
import { Button } from '@progress/kendo-react-buttons';
import { useNavigate, useParams } from 'react-router-dom';
import { EntityEditor } from '../../../types/game-document/entity-editor';
import { AreaEntity } from '../../../types/game-document/';
import { FluidForm } from '../../../components/forms';
import {
  AreaEditor,
  AreaEditorWindow
} from '../../../features/game-document/areas';
import cloneDeep from 'lodash.clonedeep';
import { Algorithm } from '../../../types/algorithm';
import { AreaEvent } from '../../../types/game-document/entities/area';
import RequiredFields from '../../../types/required-fields';
import { Col, Row } from 'react-bootstrap';
import { ActionTopRow } from '../../../components/game-designer/action-top-row';

const Area = () => {
  const { areaId } = useParams();
  const [state, setState] = useContext(GameDocumentContext);
  const navigate = useNavigate();
  const [requiredFields, setRequiredFields] = useState<
    RequiredFields<AreaEntity>[]
  >([{ name: 'name', errorMessage: '' }]);

  const getEntityById = (entityId: string) =>
    state.gameDocument?.assets.areas?.find((i) => i.id === entityId)!;

  const [entity, setEntity] = useState<AreaEntity>(() => {
    const areaEntity: AreaEntity = getEntityById(areaId!);
    return cloneDeep(areaEntity);
  });

  useEffect(() => {
    // Set the page title.
    document.title = `${entity.name} - ${state.gameDocument?.name}`;
  }, [state, entity]);

  const handleAreaChange = (
    key: string,
    value: string | Algorithm,
    type: 'entity' | 'algorithm'
  ) => {
    if (type === 'entity') {
      setRequiredFields((prev) =>
        prev.map((input: RequiredFields<AreaEntity>) => {
          const copyInput: RequiredFields<AreaEntity> = cloneDeep({ ...input });
          const entityKey = key as keyof AreaEntity;
          copyInput.errorMessage = '';
          if (entityKey === 'name') {
            const duplicateValueIndex: number =
              state.gameDocument?.assets.areas
                ?.filter((area: AreaEntity) => area.id !== entity.id)
                .findIndex((area: AreaEntity) => area.name === value) ?? -1;
            if (value === '') {
              copyInput.errorMessage = 'This field is required';
            } else if (duplicateValueIndex !== -1) {
              copyInput.errorMessage = 'Please enter unique name';
            }
          }
          return copyInput;
        })
      );
      setEntity((prev) => ({
        ...prev,
        [key as keyof AreaEntity]: value as string
      }));
      UpdateAreaAsync(
        state.gameDocument!,
        areaId!,
        {
          ...entity,
          [key as keyof AreaEntity]: value as string
        },
        false
      ).then((updatedGameDocument) => {
        setState((prev) => UpdateGameDocState(prev, updatedGameDocument));
      });
    } else {
      const copyEntity: AreaEntity = cloneDeep({ ...entity });
      if (copyEntity.events === undefined) {
        copyEntity.events = {
          onEntry: {} as Algorithm,
          onExit: {} as Algorithm
        };
      }
      copyEntity.events[key as keyof AreaEvent] = value as Algorithm;
      UpdateAreaAsync(state.gameDocument!, areaId!, copyEntity).then(
        (updatedGameDocument) => {
          setState((prev) => UpdateGameDocState(prev, updatedGameDocument));
          setEntity((prevEntity) => ({ ...prevEntity, ...copyEntity }));
        }
      );
    }
  };

  const [entityEditorIsVisible, setEntityEditorIsVisible] =
    useState<boolean>(false);
  const toggleEntityEditor = () => {
    setEntityEditorIsVisible(!entityEditorIsVisible);
  };

  const handleEntityEditorSubmit = (editorEntity: EntityEditor<AreaEntity>) => {
    UpdateAreaAsync(
      state.gameDocument!,
      editorEntity.entity.id,
      editorEntity.entity
    ).then((updatedGameDocument) => {
      setState((prev) => UpdateGameDocState(prev, updatedGameDocument));
      setEntityEditorIsVisible(false);
    });
  };

  const onDeleteEntity = async (entityId: string) => {
    DeleteAreaAsync(state.gameDocument!, entityId).then(
      (updatedGameDocument) => {
        setState((prev) => UpdateGameDocState(prev, updatedGameDocument));
        navigate('../');
      }
    );
  };

  const onViewEntity = (entityId: string) => {
    navigate(`../../map?areaId=${entityId}`);
  };

  return (
    <>
      <Toolbar title={entity?.name} />
      <ActionTopRow
        onViewClick={() => {
          onViewEntity(entity.id!);
        }}
        onDeleteClick={() => {
          onDeleteEntity(entity.id!);
        }}
      />
      <FluidForm>
        <AreaEditor
          editorMode={'full'}
          entity={entity}
          handleAreaChange={handleAreaChange}
          requiredFields={requiredFields}
        />
      </FluidForm>
    </>
  );
};

export default Area;
