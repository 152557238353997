import React, { ComponentType, ReactNode } from 'react';
import { StandardInput } from '../../../components/forms';
import { TitleEntity, ResourceEntity } from '../../../types/game-document/';
import {
  Checkbox,
  CheckboxChangeEvent,
  InputChangeEvent,
  TextAreaChangeEvent
} from '@progress/kendo-react-inputs';
import { EditorMode } from '../../../types/editor-mode';
import ImageResource from '../image-resource/resource';
import RequiredFields from '../../../types/required-fields';
import { TextareaInput } from '../../../components/form-input';
interface TitleEditorProps {
  editorMode: EditorMode;
  entity: TitleEntity;
  resources: ResourceEntity[];
  handleInputChange: (event: InputChangeEvent) => void;
  handleImageSelected?:
    | ((url: string, size: number, name?: string) => void)
    | (() => {});
  children?: ReactNode | undefined;
  imageResourceVisibility?: boolean | undefined;
  requiredFields?: RequiredFields<TitleEntity>[];
  handleHideTitleChange: (event: CheckboxChangeEvent) => void;
  handleTextAreaChange: (event: TextAreaChangeEvent) => void;
}

export const TitleEditor: ComponentType<TitleEditorProps> = ({
  editorMode,
  entity,
  resources,
  handleInputChange,
  handleImageSelected = () => {},
  imageResourceVisibility,
  requiredFields,
  handleHideTitleChange,
  handleTextAreaChange,
  ...props
}: TitleEditorProps) => {
  const handleSelectImage = (url: string, size: number, name?: string) => {
    handleImageSelected(url, size, name);
  };

  return (
    <>
      <div className={'d-flex flex-row'}>
        <div className={'flex-column w-10'}>
          <ImageResource
            title={'Image'}
            imageResource={
              resources.find((title) => title.id === entity.imageResId)!
            }
            onSubmit={handleSelectImage}
            source="Title"
          />
        </div>
        <div className="d-flex ml-3 mt-4 me-3">
          <div
            style={{
              borderRight: '2px solid #e9e9e9', // Vertical line style
              height: '60%' // Full height of the parent
            }}></div>
        </div>
        <div className={'flex-column flex-fill'}>
          <StandardInput
            name={'name'}
            label={'Name'}
            value={entity?.name}
            onChange={handleInputChange}
            validationMessage={
              requiredFields?.find((item) => item.name === 'name')?.errorMessage
            }
            autoFocus={true}
          />
          <TextareaInput
            label={'Description'}
            name={'description'}
            value={entity?.description}
            rows={4}
            onChange={handleTextAreaChange}></TextareaInput>
          <StandardInput
            name={'titleResId'}
            label={'Title'}
            value={
              resources.find((resource) => resource.id === entity?.titleResId)
                ?.value
            }
            onChange={handleInputChange}
            validationMessage={
              requiredFields?.find((item) => item.name === 'titleResId')
                ?.errorMessage
            }
          />
          <StandardInput
            name={'summaryResId'}
            label={'Summary'}
            value={
              resources.find((resource) => resource.id === entity?.summaryResId)
                ?.value
            }
            onChange={handleInputChange}
          />

          <Checkbox
            name={'hideInGame'}
            label={'Hide title from players.'}
            size={'large'}
            value={entity?.hideInGame}
            onChange={handleHideTitleChange}
          />
        </div>
      </div>

      {props.children}
    </>
  );
};
