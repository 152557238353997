import { NumericTextBox, RadioButton } from '@progress/kendo-react-inputs';
import { Row, Tabs, Tab } from 'react-bootstrap';
import { Algorithm } from '../../../types/algorithm';
import {
  ResourceEntity,
  TimerEntity
} from '../../../types/game-document/index';
import EventAlgorithm from '../../../components/map/event-configuration/event--algorithm';
import { TimerEditor } from './timer-editor';
import { ResourceType } from '../../../types/game-document/resource-type';
import { TimerEvent } from '../../../types/game-document/entities/timer';
import RequiredFields from '../../../types/required-fields';

interface TimerEditorProps {
  status?: string;
  timer?: TimerEntity;
  resources: ResourceEntity[];
  requiredFields?: RequiredFields<TimerEntity>[];
  handleEntityChange?: (
    field: keyof TimerEntity,
    value: string | number,
    type: ResourceType,
    isClearValue?: boolean
  ) => void;
  handleEventAction?: (task: TimerEntity) => void;
}

export default function TimerEditorFull({
  timer,
  resources,
  requiredFields,
  handleEntityChange = () => {},
  handleEventAction = () => {}
}: TimerEditorProps) {
  const eventActionHandler = (type: keyof TimerEvent, algorithm: Algorithm) => {
    let newTimer: TimerEntity = { ...timer! };
    if (!newTimer.events) {
      newTimer.events = {};
    }
    newTimer.events[type] = algorithm;
    handleEventAction(newTimer);
  };

  const handleEventOnStartChange = (algorithm: Algorithm) => {
    eventActionHandler('onStartTimer', algorithm);
  };

  const handleEventOnStopChange = (algorithm: Algorithm) => {
    eventActionHandler('onStopTimer', algorithm);
  };

  const handleEventOnElapsedChange = (algorithm: Algorithm) => {
    eventActionHandler('onElapsedTimer', algorithm);
  };

  const onChangeTimerHandler = (field: keyof TimerEntity, value: string) => {
    handleEntityChange(field, value, 'text');
  };

  const onChangeTimerIntervalHandler = (
    field: keyof TimerEntity,
    value: number
  ) => {
    handleEntityChange(field, value, 'text');
  };

  return (
    <Row className={'mt-3'}>
      <Tabs
        defaultActiveKey={'information'}
        id={'timer-tabs'}
        className={'mb-3'}>
        <Tab eventKey={'information'} title={'Information'}>
          <div className={'d-flex flex-column gap-2'}>
            <TimerEditor
              editorMode={'basic'}
              entity={timer!}
              resources={resources}
              handleInputChange={(event) => {
                const { name, value } = event.target;
                handleEntityChange(
                  name as keyof TimerEntity,
                  value as string,
                  'text'
                );
              }}
              handleTextAreaChange={(event) => {
                const { name, value } = event.target;
                handleEntityChange(
                  name as keyof TimerEntity,
                  value as string,
                  'text'
                );
              }}
              requiredFields={requiredFields}
            />

            <div className={'d-flex align-items-end my-2'}>
              <div className={'d-flex flex-column gap-2'}>
                <NumericTextBox
                  label={'Interval'}
                  className={'w-20 mr-1'}
                  value={timer?.interval}
                  format={'n0'}
                  onChange={(e) => {
                    onChangeTimerIntervalHandler(
                      'interval',
                      e.target.value as number
                    );
                  }}
                />
              </div>
              <span className={'mb-2 ml-1'}>Seconds</span>
            </div>

            <div className={'d-flex gap-1 align-items-center my-2'}>
              <span className={'mb-2 mr-10 w-20'}>Direction</span>
              <span className={'w-5'}>
                <RadioButton
                  name={'count'}
                  value={'up'}
                  label={'Up'}
                  checked={timer?.direction === 'up'}
                  onChange={(e) => {
                    onChangeTimerHandler('direction', e.value);
                  }}
                />
              </span>
              <span>
                <RadioButton
                  name={'count'}
                  value={'down'}
                  label={'Down'}
                  checked={
                    timer?.direction === 'down' ||
                    timer?.direction === undefined
                  }
                  onChange={(e) => {
                    onChangeTimerHandler('direction', e.value);
                  }}
                />
              </span>
            </div>

            <div className={'d-flex gap-1 align-items-center my-2'}>
              <span className={'mb-2 mr-10 w-20'}>Repeats</span>
              <span className={'w-5'}>
                <RadioButton
                  name={'repeats'}
                  value={true}
                  label={'Yes'}
                  checked={timer?.repeats === true}
                  onChange={(e) => {
                    onChangeTimerHandler('repeats', e.value);
                  }}
                />
              </span>
              <span>
                <RadioButton
                  name={'repeats'}
                  value={false}
                  label={'No'}
                  checked={
                    timer?.repeats === false || timer?.repeats === undefined
                  }
                  onChange={(e) => {
                    onChangeTimerHandler('repeats', e.value);
                  }}
                />
              </span>
            </div>
          </div>
        </Tab>
        <Tab eventKey={'onStart'} title={'onStart'}>
          <EventAlgorithm
            onChange={handleEventOnStartChange}
            algorithm={timer?.events?.onStartTimer}
            title={'Timer.onStart'}
            description={'is triggered when the timer is started.'}
          />
        </Tab>
        <Tab eventKey={'onStop'} title={'onStop'}>
          <EventAlgorithm
            onChange={handleEventOnStopChange}
            algorithm={timer?.events?.onStopTimer}
            title={'Timer.onStop'}
            description={'is triggered when the timer is stopped.'}
          />
        </Tab>
        <Tab eventKey={'onElapsed'} title={'onElapsed'}>
          <EventAlgorithm
            onChange={handleEventOnElapsedChange}
            algorithm={timer?.events?.onElapsedTimer}
            title={'Timer.onElapsed'}
            description={'is triggered when the timer is elapses.'}
          />
        </Tab>
      </Tabs>
    </Row>
  );
}
