import { useEffect, useRef, useState } from 'react';
import { Popup, Align } from '@progress/kendo-react-popup';
import { Assessment } from '../types/assessment';
import { Feedback } from '../types/feedback';

export interface popupMenu {
  classIcon?: string;
  textMenu?: string;
  textClass?: string;
}

export interface popupMenuProps {
  id: number;
  idString?: string;
  iconClass?: string;
  menus: popupMenu[];
  assessment?: Assessment;
  data?: Assessment | Feedback;
  buttonColor?: 'black' | 'white';
  onMenuSelected: (
    id: number,
    popupMenu: popupMenu,
    idString?: string,
    assessment?: Assessment,
    data?: Assessment | Feedback
  ) => void;
}

export default function PopupMenu({
  id,
  data,
  menus,
  idString,
  assessment,
  buttonColor = 'black',
  onMenuSelected
}: popupMenuProps) {
  const [show, setShow] = useState<boolean>(false);
  const anchor = useRef<HTMLSpanElement | null>(null);
  const menuRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        show &&
        menuRef.current &&
        !menuRef.current.contains(event.target as Node) &&
        anchor.current &&
        !anchor.current.contains(event.target as Node)
      ) {
        setShow(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [show]);

  const popupAlign: Align = {
    horizontal: 'right',
    vertical: 'top'
  };

  const onClick = () => {
    setShow((prev) => !prev);
  };

  const onSelected = (id: number, popupMenu: popupMenu, idString?: string) => {
    if (onMenuSelected) {
      onMenuSelected(id, popupMenu, idString, assessment, data);
      setShow(false);
    }
  };

  return (
    <div>
      <span
        onClick={onClick}
        ref={anchor}
        className={`material-symbols-outlined fw-bold cursor-pointer text-decoration-none text-${buttonColor}`}>
        more_vert
      </span>
      <Popup
        anchor={anchor.current}
        popupAlign={popupAlign}
        show={show}
        className={'popup'}>
        <div className={'py-3 d-flex flex-column'} ref={menuRef} tabIndex={0}>
          {menus &&
            menus.map((mn, index) => {
              return (
                <div
                  className={'px-3 my-2 d-flex align-items-center'}
                  role={'button'}
                  key={index}
                  onClick={() => onSelected(id, mn, idString)}>
                  <span className={`material-symbols-outlined ${mn.textClass}`}>
                    {mn.classIcon}
                  </span>
                  <span className={'mx-3'}>{mn.textMenu}</span>
                </div>
              );
            })}
        </div>
      </Popup>
    </div>
  );
}
