import { ComponentType, useContext, useState } from 'react';
import {
  Window,
  WindowActionsBar,
  WindowProps
} from '@progress/kendo-react-dialogs';
import { FluidForm } from '../../../components/forms';
import { ResourceEntity, TitleEntity } from '../../../types/game-document/';
import {
  CheckboxChangeEvent,
  InputChangeEvent,
  TextAreaChangeEvent
} from '@progress/kendo-react-inputs';
import { Button } from '@progress/kendo-react-buttons';
import { EditorMode } from '../../../types/editor-mode';
import { EntityEditor } from '../../../types/game-document/entity-editor';
import { GameDocumentContext } from '../../../contexts/game-document';
import RequiredFields from '../../../types/required-fields';
import {
  ERR_DUPLICATE_NAME_VALUE,
  ERR_INPUT_REQUIRED
} from '../../../constants/text';
import { TitleEditor } from '../../../features/game-document/titles';
import { uuid } from '../../../types/common-helper';
import Toolbar from '../toolbar';
import {
  AddResourceAsync,
  AddTitleAsync,
  UpdateGameDocState
} from '../../../utils/game-document';
import { useNavigate } from 'react-router-dom';

export const TitleNew = () => {
  const titleGuid = uuid();
  const summaryGuid = uuid();
  const imageGuid = uuid();

  const newEntity: TitleEntity = {
    id: '',
    name: '',
    description: '',
    titleResId: titleGuid,
    summaryResId: summaryGuid,
    imageResId: imageGuid
  };
  const titleResource: ResourceEntity = {
    id: titleGuid,
    name: titleGuid,
    description: '',
    type: 'text',
    value: ''
  };

  const summaryResource: ResourceEntity = {
    id: summaryGuid,
    name: summaryGuid,
    description: '',
    type: 'text',
    value: ''
  };

  const imageResource: ResourceEntity = {
    id: imageGuid,
    name: imageGuid,
    description: '',
    type: 'image',
    value: ''
  };

  const [state, setState] = useContext(GameDocumentContext);
  const [entity, setEntity] = useState<TitleEntity>(newEntity);
  const [resources, setResources] = useState<ResourceEntity[]>([
    titleResource,
    summaryResource,
    imageResource
  ]);
  const navigate = useNavigate();

  const [requiredInputs, setRequiredInputs] = useState<
    RequiredFields<TitleEntity>[]
  >([
    { name: 'name', errorMessage: '' },
    { name: 'titleResId', errorMessage: '' }
  ]);

  const handleInputChange = (event: InputChangeEvent) => {
    const { name, value } = event.target;

    if (name === 'titleResId' || name === 'summaryResId') {
      const key = entity[name]!;
      setResources(
        resources.map((title) =>
          title.id === key
            ? { ...title, value: value! as string }
            : { ...title }
        )
      );
    } else {
      setEntity({ ...entity, [name!]: value });
    }
  };

  const handleImageSelected = (url: string, size: number, name?: string) => {
    setResources(
      resources.map((title) =>
        title.id === entity.imageResId
          ? {
              ...title,
              value: url,
              size: size,
              name: title.id,
              description: name ?? ''
            }
          : title
      )
    );
  };

  const isInputValid = (): boolean => {
    const inputValidation = requiredInputs.map((input) => {
      const entityValue = entity[input.name as keyof TitleEntity];

      if (input.name === 'name') {
        const titleIndex =
          state.gameDocument?.assets?.titles?.findIndex(
            (title) =>
              typeof title.name === 'string' &&
              typeof entityValue === 'string' &&
              title.name.toLowerCase() === entityValue?.toLowerCase()
          ) ?? -1;
        if (entityValue === '') {
          input.errorMessage = ERR_INPUT_REQUIRED;
        } else if (
          titleIndex !== -1 &&
          state.gameDocument?.assets?.titles![titleIndex]?.id !== entity.id
        ) {
          input.errorMessage = ERR_DUPLICATE_NAME_VALUE;
        } else {
          input.errorMessage = '';
        }
      } else if (input.name === 'titleResId') {
        const resourceIndex = resources.findIndex(
          (resource) => resource.id === entityValue
        );
        if (resources[resourceIndex]?.value === '') {
          input.errorMessage = ERR_INPUT_REQUIRED;
        } else {
          input.errorMessage = '';
        }
      }

      return input;
    });
    setRequiredInputs(inputValidation);
    return (
      inputValidation.findIndex((input) => input.errorMessage !== '') === -1
    );
  };

  const handleHideTitleChange = (event: CheckboxChangeEvent) => {
    const { name, value } = event.target;
    setEntity({ ...entity, [name!]: value });
  };

  const handleTextAreaChange = (event: TextAreaChangeEvent) => {
    const { name, value } = event.target;
    setEntity({ ...entity, [name!]: value });
  };

  const handleSubmit = (
    editorEntity: EntityEditor<TitleEntity>,
    resourceEntity: EntityEditor<ResourceEntity>[]
  ) => {
    const addTitleResource = AddResourceAsync(
      state.gameDocument!,
      'Title',
      '',
      'text',
      resourceEntity.find(
        (title) => title.entity.id === editorEntity.entity.titleResId!
      )?.entity.value!,
      titleGuid
    );
    const addSummaryResource = AddResourceAsync(
      state.gameDocument!,
      'Summary',
      '',
      'text',
      resourceEntity.find(
        (title) => title.entity.id === editorEntity.entity.summaryResId!
      )?.entity.value!,
      summaryGuid
    );

    const imageResource = resourceEntity.find(
      (item) => item.entity.id === editorEntity.entity.imageResId!
    )?.entity;
    const addImageResource = AddResourceAsync(
      state.gameDocument!,
      imageGuid,
      imageResource?.description ?? '',
      'image',
      imageResource?.value ?? '',
      imageGuid,
      imageResource?.size ?? 0
    );
    Promise.all([addTitleResource, addSummaryResource, addImageResource]).then(
      () => {
        const {
          entity: { name, description }
        } = editorEntity;
        AddTitleAsync(
          state.gameDocument!,
          name,
          description,
          titleGuid,
          summaryGuid,
          imageGuid,
          editorEntity.entity.hideInGame
        ).then((updatedGameDocument) => {
          setState((prev) => UpdateGameDocState(prev, updatedGameDocument));
          navigate(-1);
        });
      }
    );
  };

  return (
    <>
      <Toolbar title={'New Title'} showDefaultContent={false}>
        <div className={'d-flex ml-2 gap-2 pl-3'}>
          <Button
            themeColor={'secondary'}
            onClick={() => {
              navigate(-1);
            }}>
            Back
          </Button>
          <Button
            themeColor={'primary'}
            onClick={() => {
              if (isInputValid()) {
                const entityResources = resources.map((item, index) => ({
                  isNew: true,
                  entity: item
                })) as EntityEditor<ResourceEntity>[];
                handleSubmit({ isNew: true, entity }, entityResources);
              }
            }}>
            Create
          </Button>
        </div>
      </Toolbar>
      <FluidForm>
        <TitleEditor
          editorMode={'full'}
          entity={entity}
          resources={resources}
          handleInputChange={handleInputChange}
          handleImageSelected={handleImageSelected}
          requiredFields={requiredInputs}
          handleHideTitleChange={handleHideTitleChange}
          handleTextAreaChange={handleTextAreaChange}
        />
      </FluidForm>
    </>
  );
};
