import { Button } from '@progress/kendo-react-buttons';
import { Col, Row } from 'react-bootstrap';

interface ActionTopRow {
  onViewClick?: () => void;
  onDeleteClick?: () => void;
}

export const ActionTopRow = ({ onViewClick, onDeleteClick }: ActionTopRow) => {
  return (
    <Row
      className="my-2"
      style={{
        borderBottom: '1px solid #e9e9e9',
        borderTop: '1px solid #e9e9e9'
      }}>
      <Col className="mt-2" md="8" xl="8" xs="8">
        Info
      </Col>
      <Col
        className="d-flex align-items-end justify-content-end"
        md="4"
        xl="4"
        xs="4">
        {onViewClick && (
          <Button
            onClick={() => onViewClick()}
            themeColor={'info'}
            fillMode={'flat'}>
            <span className="material-symbols-outlined">visibility</span>
          </Button>
        )}
        {onDeleteClick && (
          <Button
            onClick={() => onDeleteClick()}
            themeColor={'error'}
            fillMode={'flat'}>
            <span className="material-symbols-outlined">delete</span>
          </Button>
        )}
      </Col>
    </Row>
  );
};
