import { process, State } from '@progress/kendo-data-query';
import { Button, Button as KendoButton } from '@progress/kendo-react-buttons';
import {
  DropDownList,
  DropDownListChangeEvent
} from '@progress/kendo-react-dropdowns';
import {
  Grid,
  GridCellProps,
  GridColumn as Column,
  GridDataStateChangeEvent,
  GridNoRecords,
  GridToolbar
} from '@progress/kendo-react-grid';
import { Checkbox } from '@progress/kendo-react-inputs';
import { Error, Label } from '@progress/kendo-react-labels';
import cloneDeep from 'lodash.clonedeep';
import {
  cloneElement,
  Dispatch,
  ReactElement,
  SetStateAction,
  useCallback,
  useEffect,
  useState
} from 'react';
import { Col, Row, Tab, Tabs } from 'react-bootstrap';
import LanguageComboBox from '../../components/combobox/language-combobox';
import YesNoDialog from '../../components/dialogs/yes-no-dialog';
import { FormInput } from '../../components/form-input';
import { StandardInput, StandardTextarea } from '../../components/forms';
import CustomColumnImage from '../../components/grid/custom-column/custom-column-image';
import CustomColumnPlainText from '../../components/grid/custom-column/custom-column-plain-text';
import CustomColumnTextarea from '../../components/grid/custom-column/custom-column-textarea';
import CustomColumnVideo from '../../components/grid/custom-column/custom-column-video';
import {
  GridToolBar,
  GridToolbarDataStateChangeEvent
} from '../../components/grid/grid-tool-bar';
import { NoRecords } from '../../components/grid/no-records';
import { DefaultGridSettings } from '../../constants/grid-settings';
import { Assessment, AssessmentOutcomes } from '../../types/assessment';
import { uuid } from '../../types/common-helper';
import { YesNoDialogResult } from '../../types/dialog-result';
import { ResourceEntity } from '../../types/game-document/entities';
import {
  LanguageMapping,
  ResourcePack
} from '../../types/game-document/entities/resource-pack';
import RequiredFields from '../../types/required-fields';
import { formatDateToDDMMYYYY } from '../../utils/date';
import {
  ResourceWindow,
  UploadedImage
} from '../game-document/image-resource/resource-window';
import { ResourcePackHtmlEditorWindow } from '../game-document/resource-packs/resource-pack-html-editor-window';
import {
  AddResourceEntityAsync,
  AddResourcePackResourceAsync,
  copyResourceAndPackById,
  DeleteResourceAsync,
  DeleteResourcePackResourceAsync,
  GetResourceById,
  GetResourceEntity,
  GetResourcePackByName,
  GetResourcePackResource,
  GetResourcePacks,
  GetResourceValue,
  UpdateResourceAsync,
  UpdateResourcePackResourceAsync
} from './assessment-document';
import { PostMediaAsync } from '../../services/files';
import PopupMenu, { popupMenu } from '../../components/popupMenu';
import CoverImage from '../../components/cover-image';
import { DisplayLanguagePublished } from '../../services/admin-display-language';
import { CountryResponse } from '../../types/country-response';
import { DefaultAssessmentSettings } from '../../constants/default-asessment-settings';

export interface AssessmentContentFullEditorProps {
  languages?: CountryResponse[];
  displayLanguages?: DisplayLanguagePublished[];
  entity: Assessment;
  requiredFields?: RequiredFields<Assessment>[];
  isViewMode?: boolean;
  currentLocale: string | undefined;
  setCurrentLocale: Dispatch<SetStateAction<string | undefined>>;
  toggleLanguageTab: (activeTab: string) => void;
  handleEntityChange?: (newEntity: Assessment) => void;
  handleAssessmentLanguages?: (newEntity: string[]) => void;
  onAddEntity?: () => void;
  onEditEntity?: () => void;
}

type selectedResource = {
  isOriginal?: boolean;
  resourceId?: string;
  type?: string;
  value?: string;
};

export const AssessmentContentFullEditor = ({
  languages,
  displayLanguages,
  entity,
  requiredFields,
  isViewMode = false,
  currentLocale,
  setCurrentLocale,
  toggleLanguageTab,
  onAddEntity,
  onEditEntity,
  handleEntityChange = () => {},
  handleAssessmentLanguages = () => {}
}: AssessmentContentFullEditorProps) => {
  const [outcomeList, setOutcomeList] = useState<
    AssessmentOutcomes[] | undefined
  >(entity?.assessmentOutcomes ?? []);
  const [showUploadLogo, setShowUploadLogo] = useState<boolean>(false);
  const [showDeleteLogo, setShowDeletedLogo] = useState<boolean>(false);
  const initialDataState: State = {
    ...DefaultGridSettings.initialDataState
  };
  const [dataState, setDataState] = useState<State>(initialDataState);
  const [languageMap, setLanguageMap] = useState<LanguageMapping[]>();
  const [assessmentLanguage, setAssessmentLanguage] = useState<string[]>();
  const [uploadMediaIsVisible, setUploadMediaIsVisible] =
    useState<boolean>(false);
  const [htmlEditorVisible, setHtmlEditorVisible] = useState<boolean>(false);
  const [selectedResource, setSelectedResource] = useState<selectedResource>();
  const [showDeleteLanguageConfirm, setShowDeleteLanguageConfirm] =
    useState<boolean>(false);
  const [settings, setSettings] = useState(
    entity.settings || DefaultAssessmentSettings
  );

  useEffect(() => {
    onChangeAssessmentInfoHandler(settings, 'settings');
  }, [settings]);

  const defaultOutcome = (): AssessmentOutcomes => {
    return {
      id: uuid(),
      titleResId: uuid(),
      title: '',
      descriptionResId: uuid(),
      description: ''
    };
  };

  const [menuList] = useState<popupMenu[]>([
    { classIcon: 'edit', textMenu: 'Edit', textClass: '' },
    { classIcon: 'delete', textMenu: 'Delete', textClass: 'text-danger' }
  ]);

  const defaultResource = (): ResourceEntity => {
    return {
      id: '',
      name: '',
      description: '',
      value: '',
      type: ''
    };
  };

  const addOutcome = () => {
    let newEntity = cloneDeep(entity);
    let outcomes = cloneDeep(outcomeList);
    let newOutcome = defaultOutcome();
    outcomes?.push(newOutcome);

    setOutcomeList(outcomes);

    let titleResource = defaultResource();
    titleResource[`id`] = newOutcome.titleResId!;
    titleResource[`name`] = 'TitleResId';
    titleResource[`description`] = 'TitleResId';
    titleResource[`type`] = 'text';

    AddResourceEntityAsync(newEntity, titleResource).then(
      (responseTitleEntity) => {
        let descriptionResource = defaultResource();
        descriptionResource[`id`] = newOutcome.descriptionResId!;
        descriptionResource[`name`] = 'DescriptionResId';
        descriptionResource[`description`] = 'DescriptionResId';
        descriptionResource[`type`] = 'text';

        AddResourceEntityAsync(responseTitleEntity, descriptionResource).then(
          (responseDescriptionEntity) => {
            responseDescriptionEntity[`assessmentOutcomes`] = outcomes;
            handleEntityChange(responseDescriptionEntity);
          }
        );
      }
    );
  };

  const onChangeAssessmentInfoHandler = (
    value: any,
    keyId: keyof Assessment
  ) => {
    let newEntity = cloneDeep(entity);
    newEntity[`${keyId}`] = value as never;
    // edit related fields via its resource
    if (newEntity.resources && newEntity.resources?.length > 0) {
      if (keyId === 'name') {
        updateResourceValue(newEntity.nameResId!, value, 'name');
      } else if (keyId === 'title') {
        updateResourceValue(newEntity.titleResId!, value, 'title');
      } else if (keyId === 'description') {
        updateResourceValue(newEntity.descriptionResId!, value, 'description');
      } else if (keyId === 'htmlContent') {
        updateResourceValue(newEntity.htmlContentResId!, value, 'htmlContent');
      }
    }
    handleEntityChange(newEntity);
  };

  const onChangeOutcomeHandler = async (
    e: any,
    index: number,
    type: string
  ) => {
    let newEntity = cloneDeep(entity);
    let forms = cloneDeep(outcomeList);
    let updatedResource: ResourceEntity = defaultResource();

    // edit resources (title atau description outcome), tambahin function baru di assessment document

    if (type === 'title') {
      forms![index].title = e.target.value;
      updatedResource = GetResourceById(newEntity, forms![index].titleResId!);
      updatedResource[`value`] = e.target.value;
    } else if (type === 'description') {
      forms![index].description = e.target.value;
      updatedResource = GetResourceById(
        newEntity,
        forms![index].descriptionResId!
      );
      updatedResource[`value`] = e.target.value;
    }

    setOutcomeList(forms);
    newEntity[`assessmentOutcomes`] = forms;

    await UpdateResourceAsync(
      newEntity,
      forms![index].id,
      updatedResource
    ).then((response) => {
      handleEntityChange(response);
    });
  };

  const onDeleteOutcomeHandler = (index: number) => {
    let newEntity = cloneDeep(entity);

    let deletedOutcome = outcomeList?.find(
      (item, indexItem) => indexItem === index
    );

    const newForms =
      outcomeList && outcomeList.length > 0
        ? outcomeList.filter((item, indexItem) => indexItem !== index)
        : [];

    setOutcomeList(newForms);

    // delete resource and resource inside resource packs, note that there's 2 deleted resources for 1 deleted outcome (titleResId and descResId)

    // titleResId in resource
    DeleteResourceAsync(newEntity, deletedOutcome?.titleResId!).then(
      (deletedTitleResEntity) => {
        // descResId in resource

        DeleteResourceAsync(
          deletedTitleResEntity,
          deletedOutcome?.descriptionResId!
        ).then((deletedDescResEntity) => {
          // titleResId in resourcePack resource

          DeleteResourcePackResourceAsync(
            deletedDescResEntity,
            deletedOutcome?.titleResId!
          ).then((deletedTitleResPackEntity) => {
            // descResId in resourcePack resource

            DeleteResourcePackResourceAsync(
              deletedTitleResPackEntity,
              deletedOutcome?.descriptionResId!
            ).then((entityResult) => {
              // entity result
              entityResult[`assessmentOutcomes`] = newForms;
              handleEntityChange(entityResult);
            });
          });
        });
      }
    );
  };

  const onCopyOutcomeHandler = (index: number) => {
    let newForms = cloneDeep(outcomeList);
    let newEntity = cloneDeep(entity);

    const newForm = cloneDeep(outcomeList![index]);
    if (newForm && newForm.id) {
      newForm['id'] = uuid();
      newForm['titleResId'] = uuid();
      newForm['descriptionResId'] = uuid();
    }

    // copy title, then copy description res ID resource and resource packs
    copyResourceAndPackById(
      newEntity,
      outcomeList![index].titleResId!,
      newForm.titleResId!
    ).then((responseTitle) => {
      copyResourceAndPackById(
        responseTitle!,
        outcomeList![index].descriptionResId!,
        newForm.descriptionResId!
      ).then((responseDesc) => {
        newForms!.push(newForm);
        setOutcomeList(newForms);
        responseDesc![`assessmentOutcomes`] = newForms;

        handleEntityChange(responseDesc!);
      });
    });
  };

  const toggleUploadMediaEditor = () => {
    setUploadMediaIsVisible(!uploadMediaIsVisible);
  };

  const toggleHtmlEditor = () => {
    setHtmlEditorVisible(!htmlEditorVisible);
  };

  const rowRender = (trElement: ReactElement<HTMLTableRowElement>) => {
    const trProps: any = { className: 'k-master-row k-grid-edit-row d-flex' };
    return cloneElement(
      trElement,
      { ...trProps },
      trElement.props.children as any
    );
  };

  const languageSelectedEvent = (event: DropDownListChangeEvent) => {
    setCurrentLocale(event.value);
  };

  const onDeleteHandler = () => {
    setShowDeleteLanguageConfirm(true);
  };

  const updateResourceValue = (
    resourceId: string,
    value: string,
    key?: string
  ) => {
    let newEntity = cloneDeep(entity);
    let resource = GetResourceEntity(newEntity, resourceId);

    if (resource) {
      // used for updating related fields while updating the related resource
      if (key === 'name') {
        newEntity.name = value;
      } else if (key === 'title') {
        newEntity.title = value;
      } else if (key === 'description') {
        newEntity.description = value;
      } else if (key === 'htmlContent') {
        newEntity.htmlContent = value;
      }

      handleEntityChange(newEntity);

      resource.value = value;
      UpdateResourceAsync(newEntity, resource?.id, resource).then(
        (response) => {
          handleEntityChange(response);
        }
      );
    }
  };

  const updateResourcePackValue = (resourceId: string, value: string) => {
    if (currentLocale && currentLocale !== '') {
      let resource = GetResourcePackResource(entity, currentLocale, resourceId);

      // update existing resource pack resource
      if (resource) {
        resource.value = value;
        UpdateResourcePackResourceAsync(
          entity,
          currentLocale,
          resource.id,
          resource
        ).then((response) => {
          handleEntityChange(response!);
        });
      }

      // add new resource pack resource
      if (!resource) {
        let originalResource = cloneDeep(GetResourceEntity(entity, resourceId));
        originalResource.value = value;

        AddResourcePackResourceAsync(
          entity,
          currentLocale,
          originalResource!
        ).then((response) => {
          handleEntityChange(response!);
        });
      }
    }
  };

  const updateResourceHandler = (
    isOriginal: boolean,
    resourceId: string,
    value: string
  ) => {
    if (isOriginal) {
      // Update the original resource (entity.resources)
      updateResourceValue(resourceId, value);
    }
    if (!isOriginal) {
      //Update the resource packs (entity.resourcePacks)
      updateResourcePackValue(resourceId, value);
    }
  };

  const handleUploadMedia = (
    isOriginal: boolean,
    resourceId: string,
    type: 'image' | 'video'
  ) => {
    setSelectedResource({
      isOriginal: isOriginal,
      resourceId: resourceId,
      type: type
    });

    toggleUploadMediaEditor();
  };

  const handleHtmlEditor = (
    isOriginal: boolean,
    resourceId: string,
    type: 'Markdown' | 'Html'
  ) => {
    let value: string = '';

    if (isOriginal) {
      value = GetResourceValue(entity, resourceId);
    }

    if (!isOriginal) {
      value =
        GetResourcePackResource(entity, currentLocale!, resourceId)?.value ??
        '';
    }

    setSelectedResource({
      isOriginal: isOriginal,
      resourceId: resourceId,
      type: type,
      value: value
    });

    toggleHtmlEditor();
  };

  const handleUploadMediaSubmit = (uploadMedia: UploadedImage) => {
    updateResourceHandler(
      selectedResource?.isOriginal!,
      selectedResource?.resourceId!,
      uploadMedia?.blobUrl!
    );

    toggleUploadMediaEditor();
  };

  const handleHtmlEditorSubmit = (value: string) => {
    updateResourceHandler(
      selectedResource?.isOriginal!,
      selectedResource?.resourceId!,
      value!
    );

    toggleHtmlEditor();
  };

  const onConfirmDeleteLanguage = async (result: YesNoDialogResult) => {
    if (result === 'yes') {
      const newAssessment = {
        ...entity,
        resourcePacks: entity.resourcePacks?.filter(
          (resourcePack) => resourcePack.name !== currentLocale
        )
      } as Assessment;

      handleEntityChange(newAssessment);
      setCurrentLocale('');
    }
    setShowDeleteLanguageConfirm(false);
  };

  const handleUploadLogo = (uploadedFile: UploadedImage) => {
    fetch(uploadedFile.blobUrl)
      .then((response) => response.blob())
      .then((blobData) => {
        const uniqueFilename = uuid() + uploadedFile.fileName;

        // Creating a Blob
        const blob = new Blob([blobData], { type: 'application/octet-stream' });

        // Creating a File by converting the Blob
        const fileObject = new File([blob], uniqueFilename, {
          type: 'application/octet-stream',
          lastModified: Date.now()
        });

        if (fileObject) {
          PostMediaAsync(fileObject).then((response) => {
            let newEntity = cloneDeep(entity);
            newEntity.logoUrl = response.mediaUrl;
            handleEntityChange(newEntity);
            setShowUploadLogo(false);
          });
        }
      })
      .catch((error) => {
        console.error('Error fetching blob data:', error);
      });
  };

  const handleDeleteLogo = (result: YesNoDialogResult) => {
    if (result === 'yes') {
      let newEntity = cloneDeep(entity);
      newEntity.logoUrl = '';
      handleEntityChange(newEntity);
    }

    setShowDeletedLogo(false);
  };

  const onMenuSelected = (id: number, menu: popupMenu, idString?: string) => {
    if (menu.textMenu === 'Delete') {
      setShowDeletedLogo(true);
    }
    if (menu.textMenu === 'Edit') {
      setShowUploadLogo(true);
    }
  };

  const CustomCell = (isOriginal: boolean, props: GridCellProps) => {
    const resource = languageMap?.find((x) => x.id === props.dataItem.id);

    if (!isOriginal && (!currentLocale || currentLocale === '')) {
      return <></>;
    }

    const resourcePack = entity.resourcePacks!.find(
      (resPack: ResourcePack) => resPack.name === currentLocale
    );

    if (resource?.type === 'image') {
      return (
        <CustomColumnImage
          columnClass={'w-50'}
          containerClass={'d-flex justify-content-end w-full p-2'}
          url={isOriginal ? resource?.originalLanguage : resource?.newLanguage}
          id={resource?.id}
          onEdit={(id) => {
            if (!isViewMode) {
              handleUploadMedia(isOriginal, id!, 'image');
            }
          }}
          maxHeight={100}
        />
      );
    }
    if (resource?.type === 'video') {
      return (
        <CustomColumnVideo
          columnClass={'w-50'}
          containerClass={'d-flex justify-content-end w-full p-2'}
          url={isOriginal ? resource?.originalLanguage : resource?.newLanguage}
          id={resource?.id}
          onEdit={(id) => {
            if (!isViewMode) {
              handleUploadMedia(isOriginal, id!, 'video');
            }
          }}
          maxHeight={100}
        />
      );
    }

    if (resource?.type === 'text') {
      const newLanguage = resourcePack
        ? resourcePack.resources.find(
            (res: any) => res.id === props.dataItem.id
          )?.value
        : resource?.newLanguage;
      return (
        <CustomColumnTextarea
          columnClass={'w-50'}
          containerClass={
            'd-flex flex-column gap-1 justify-content-between w-full p-2'
          }
          label={resource.label}
          labelStyleClass="title-boxes"
          id={`${isOriginal ? 'original' : ''}-${resource?.id}`}
          onChange={(id, value) => {
            if (!isViewMode) {
              updateResourceHandler(isOriginal, resource?.id!, value!);
            }
          }}
          text={isOriginal ? resource?.originalLanguage : newLanguage}
          rows={1}
          autoSize
          isDisable={isViewMode}
        />
      );
    }
    if (resource?.type === 'text-long') {
      return (
        <CustomColumnTextarea
          columnClass={'w-50'}
          id={resource?.id}
          containerClass={'d-flex justify-content-center w-100 p-2'}
          onChange={(id, value) => {
            if (!isViewMode) {
              updateResourceHandler(isOriginal, id!, value!);
            }
          }}
          text={isOriginal ? resource?.originalLanguage : resource?.newLanguage}
          isDisable={isViewMode}
        />
      );
    }
    if (resource?.type === 'text-md') {
      return (
        <CustomColumnPlainText
          columnClass={'w-50'}
          id={resource?.id}
          onEdit={(id) => {
            if (!isViewMode) {
              handleHtmlEditor(isOriginal, id!, 'Markdown');
            }
          }}
          containerClass={'d-flex justify-content-between w-full p-2'}
          text={'Markdown'}
        />
      );
    }
    if (resource?.type === 'text-html') {
      return (
        <CustomColumnPlainText
          columnClass={'w-50'}
          id={resource?.id}
          onEdit={(id) => {
            if (!isViewMode) {
              handleHtmlEditor(isOriginal, id!, 'Html');
            }
          }}
          containerClass={'d-flex justify-content-between w-full p-2'}
          text={'Html'}
        />
      );
    }
    return (
      <CustomColumnPlainText
        columnClass={'w-50'}
        containerClass={'d-flex justify-content-between w-full p-2'}
        text={isOriginal ? resource?.originalLanguage : resource?.newLanguage}
      />
    );
  };

  const languageOrder = useCallback(() => {
    const { nameResId, titleResId, htmlContentResId, descriptionResId } =
      entity;
    let outcomeIds: { label: string; id: string }[] = [];
    if (outcomeList) {
      outcomeIds = [
        ...outcomeList.flatMap((c, idx) => [
          {
            label: `Title (Competency ${idx + 1})`,
            id: c.titleResId as string
          },
          {
            label: `Description (Competency ${idx + 1})`,
            id: c.descriptionResId as string
          }
        ])
      ];
    }

    return [
      { label: 'Assessment name', id: nameResId },
      { label: 'Assessment title', id: titleResId },
      { label: 'Purpose statement', id: descriptionResId },
      { label: 'HTML content', id: htmlContentResId },
      ...outcomeIds
    ];
  }, [entity, outcomeList]);

  useEffect(() => {
    const { htmlContentResId, nameResId } = entity;
    // set language mapping (language table contents, original - other language(s))
    let map: LanguageMapping[] = GetResourcePackByName(
      entity!,
      currentLocale ?? ''
    );
    const orderedIds = languageOrder();
    map.sort(
      (a, b) =>
        orderedIds.findIndex((ordered) => ordered.id === a.id) -
        orderedIds.findIndex((ordered) => ordered.id === b.id)
    );

    const languageMapWithLabel = map
      .map((lang) => ({
        ...lang,
        label: orderedIds.find((ordered) => ordered.id === lang.id)?.label
      }))
      .filter((lang) => lang.id !== htmlContentResId && lang.id !== nameResId);
    setLanguageMap(languageMapWithLabel);

    // set assessment language (language list from resource pack(s)) for language dropdown picker
    let resourcePacks = GetResourcePacks(entity);
    let languageList: string[] = [];
    resourcePacks.forEach((resourcePack) => {
      languageList.push(resourcePack.name);
    });
    setAssessmentLanguage(languageList);
    handleAssessmentLanguages(languageList);

    // set currentLocale (chosen language) to be default when empty
    if (currentLocale === undefined || currentLocale === '') {
      setCurrentLocale(languageList[0]);
    }
  }, [entity, currentLocale]);

  return (
    <>
      <Row className={'mt-3'}>
        <Tabs
          defaultActiveKey={'information'}
          id={'assessment-tabs'}
          className={'mb-3'}
          onSelect={(selectedTab) => toggleLanguageTab(selectedTab ?? '')}
          mountOnEnter>
          <Tab eventKey={'information'} title={'Information'}>
            <div className={'gap-2'}>
              <Row className={'flex-grow-1 mb-3'}>
                <Col sm={'6'} md={'6'} lg={'6'} xl={'6'}>
                  <div className={'d-flex w-full flex-wrap'}>
                    <div className={'flex-column w-10 me-3'}>
                      <CoverImage
                        imageUrl={entity?.logoUrl}
                        containerStyle={'rounded'}
                        className={'mr-5 mb-3'}
                        style={{ flex: 1 }}
                        onDropFiles={(uploadedFile) => {}}>
                        <PopupMenu
                          menus={menuList}
                          id={Math.floor(Math.random() * (30 - 1 + 1)) + 1}
                          onMenuSelected={onMenuSelected}></PopupMenu>
                      </CoverImage>
                    </div>
                    <div
                      className={
                        'author-card border border-1 bg-light p-2 rounded d-grid flex-grow-1'
                      }>
                      <Label>Author: {entity?.createdBy}</Label>
                      <Label>
                        Created:{' '}
                        {entity?.createdDateUtc
                          ? formatDateToDDMMYYYY(entity?.createdDateUtc, true)
                          : ''}
                      </Label>
                      <Label>
                        Last Updated:{' '}
                        {entity?.modifiedDateUtc
                          ? formatDateToDDMMYYYY(entity?.modifiedDateUtc, true)
                          : ''}
                      </Label>
                      <Label>
                        Version:{' '}
                        <code>
                          {entity?.version?.includes('v')
                            ? entity?.version
                            : `v${entity?.version}`}
                        </code>
                      </Label>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={'6'} md={'6'} lg={'3'} xl={'3'}>
                  <StandardInput
                    disabled={isViewMode}
                    name={'name'}
                    label={'Assessment name'}
                    value={entity.name}
                    onChange={(e) => {
                      onChangeAssessmentInfoHandler(
                        e.target.value as string,
                        e.target.name! as keyof Assessment
                      );
                    }}
                    labelStyle={'fw-bold'}
                  />
                </Col>

                <Col sm={'6'} md={'6'} lg={'3'} xl={'3'}>
                  <Label className="fw-bold">Language</Label>
                  <LanguageComboBox
                    disabled={isViewMode}
                    name={'language'}
                    value={
                      entity?.language
                        ? languages?.find(
                            (item) => item.language === entity.language!
                          )?.language
                        : null
                    }
                    onChange={(e) => {
                      onChangeAssessmentInfoHandler(
                        e.target.value as string,
                        e.target.name! as keyof Assessment
                      );
                    }}
                  />
                  {!entity.language && <Error>{'Language is required'}</Error>}
                </Col>
              </Row>
              {!entity.name && <Error>{'Name is required'}</Error>}
              <Row className={'mt-3'}>
                <Col sm={'12'} md={'12'} lg={'6'} xl={'6'}>
                  <StandardInput
                    disabled={isViewMode}
                    name={'title'}
                    label={'Assessment title'}
                    labelStyle={'fw-bold'}
                    value={entity?.title}
                    onChange={(e) => {
                      onChangeAssessmentInfoHandler(
                        e.target.value as string,
                        e.target.name! as keyof Assessment
                      );
                    }}
                  />
                </Col>
              </Row>
              <Row className={'mt-4'}>
                <Col sm={'12'} md={'12'} lg={'6'} xl={'6'}>
                  <StandardTextarea
                    disabled={isViewMode}
                    name={'description'}
                    label={'Purpose statement'}
                    labelStyle={'fw-bold'}
                    rows={10}
                    value={entity?.description}
                    onChange={(e) => {
                      onChangeAssessmentInfoHandler(
                        e.target.value as string,
                        e.target.name! as keyof Assessment
                      );
                    }}
                  />
                </Col>
              </Row>
            </div>
          </Tab>
          {/* Hide HTML Section regarding task #5758 */}
          {/* <Tab eventKey={'bodyType'} title={'HTML'}>
            <Row>
              <Col sm={'8'} md={'8'} lg={'8'} xl={'8'}>
                <HtmlEditor
                  value={entity.htmlContent}
                  handleHtmlChange={(e) => {
                    if (!isViewMode) {
                      onChangeAssessmentInfoHandler(e.html, 'htmlContent');
                    }
                  }}
                />
              </Col>
            </Row>
          </Tab> */}
          <Tab eventKey={'outcome'} title={'Competencies'}>
            <Row>
              {outcomeList &&
                outcomeList.map((item, index) => {
                  return (
                    <Row key={index}>
                      <div className={'card p-3 ml-3 mt-3 bg-base-bg'}>
                        <Col sm={12} className={'mb-3'}>
                          <FormInput
                            disabled={isViewMode}
                            name={'titleResId'}
                            label={'Title'}
                            className={'w-100'}
                            value={item.title}
                            onChange={(e) => {
                              onChangeOutcomeHandler(e, index, 'title');
                            }}
                          />
                        </Col>
                        <Col sm={12}>
                          <Row>
                            <StandardTextarea
                              disabled={isViewMode}
                              label={'Description'}
                              value={item.description}
                              rows={5}
                              onChange={(e) => {
                                onChangeOutcomeHandler(e, index, 'description');
                              }}
                            />
                          </Row>
                          {!isViewMode && (
                            <Row>
                              <div className={'d-flex justify-content-end'}>
                                <span
                                  className={
                                    'material-symbols-outlined cursor-pointer p-2'
                                  }
                                  onClick={() => onCopyOutcomeHandler(index)}>
                                  content_copy
                                </span>

                                <span
                                  className={
                                    'material-symbols-outlined cursor-pointer text-danger p-2'
                                  }
                                  onClick={() => onDeleteOutcomeHandler(index)}>
                                  delete
                                </span>
                              </div>
                            </Row>
                          )}
                        </Col>
                      </div>
                    </Row>
                  );
                })}
            </Row>
            {!isViewMode && (
              <Row>
                <Col>
                  <div
                    className={'card cursor-pointer p-3 mt-3 bg-base-bg'}
                    onClick={addOutcome}>
                    <div className={'card-body d-flex justify-content-center'}>
                      <span className={'material-symbols-outlined'}>dns</span>
                      <Label className={'cursor-pointer'}>
                        {'Add competency'}
                      </Label>
                    </div>
                  </div>
                </Col>
              </Row>
            )}
          </Tab>

          {entity.id !== 0 && (
            <Tab eventKey={'language'} title={'Language'}>
              <div className={'pt-2'}>
                <GridToolBar
                  searchPlaceholder={'Search items'}
                  columnsToSearch={['newLanguage', 'originalLanguage']}
                  showCardMode={false}
                  {...dataState}
                  onDataStateChange={(e: GridToolbarDataStateChangeEvent) => {
                    setDataState(e.dataState);
                  }}></GridToolBar>

                {languageMap && languageMap.length > 0 && (
                  <Grid
                    pageable={DefaultGridSettings.pagerSettings}
                    editField={'isEditing'}
                    className={'cg-grid3 w-full'}
                    data={process(languageMap ?? [], dataState)}
                    dataItemKey={'id'}
                    {...dataState}
                    onDataStateChange={(e: GridDataStateChangeEvent) => {
                      setDataState(e.dataState);
                    }}
                    rowRender={rowRender}>
                    <GridToolbar>
                      <Row
                        className={
                          'd-flex justify-content-between w-100 align-items-center'
                        }>
                        <Col>
                          <span className={'fw-bold'}>Original</span>
                        </Col>
                        <Col>
                          <div className="d-flex justify-content-between gap-2">
                            <DropDownList
                              className={'bg-white fw-bold w-100'}
                              value={currentLocale}
                              onChange={languageSelectedEvent}
                              data={assessmentLanguage}
                            />
                            {!isViewMode &&
                              assessmentLanguage &&
                              currentLocale && (
                                <KendoButton
                                  disabled={isViewMode}
                                  title={'Edit'}
                                  onClick={onEditEntity}
                                  themeColor={'secondary'}
                                  size={'medium'}
                                  className={'medium'}
                                  fillMode={'flat'}>
                                  <span className="material-symbols-outlined">
                                    {'edit'}
                                  </span>
                                </KendoButton>
                              )}
                            {!isViewMode &&
                              assessmentLanguage &&
                              currentLocale && (
                                <KendoButton
                                  disabled={isViewMode}
                                  title={'Delete'}
                                  onClick={onDeleteHandler}
                                  themeColor={'error'}
                                  size={'medium'}
                                  className={'medium'}
                                  fillMode={'flat'}>
                                  <span className="material-symbols-outlined">
                                    {'delete'}
                                  </span>
                                </KendoButton>
                              )}

                            {!isViewMode && (
                              <Button
                                themeColor={'success'}
                                onClick={onAddEntity}>
                                + Create new
                              </Button>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </GridToolbar>
                    <Column
                      groupable={false}
                      field={'originalLanguage'}
                      editable={false}
                      headerClassName={'d-none'}
                      cell={(props) => CustomCell(true, props)}
                    />

                    {currentLocale !== '' && (
                      <Column
                        groupable={false}
                        field={'newLanguage'}
                        headerClassName={'d-none'}
                        className={'w-50'}
                        cell={(props) => CustomCell(false, props)}
                      />
                    )}

                    <GridNoRecords>
                      <NoRecords />
                    </GridNoRecords>
                  </Grid>
                )}
              </div>
              {uploadMediaIsVisible && (
                <ResourceWindow
                  toggleDialog={toggleUploadMediaEditor}
                  onSubmit={handleUploadMediaSubmit}
                  acceptedExtension={`${selectedResource?.type}/*`}
                  title={`Upload ${selectedResource?.type}`}
                  onClose={(e) => toggleUploadMediaEditor()}
                />
              )}
              {htmlEditorVisible && (
                <ResourcePackHtmlEditorWindow
                  isViewMode={isViewMode}
                  toggleDialog={toggleHtmlEditor}
                  text={selectedResource?.value}
                  type={selectedResource?.type as any}
                  onSubmit={handleHtmlEditorSubmit}
                  onClose={toggleHtmlEditor}
                />
              )}
              {showDeleteLanguageConfirm && (
                <YesNoDialog
                  title="Confirm removal"
                  onConfirm={onConfirmDeleteLanguage}
                  onClose={() => setShowDeleteLanguageConfirm(false)}>
                  Are you sure you want to remove the language?
                </YesNoDialog>
              )}
            </Tab>
          )}

          {entity.id !== 0 && (
            <Tab
              eventKey={'settings'}
              className="assessment-tabs-tab-settings"
              title={'Settings'}>
              <Row className={'gy-2'}>
                <Col xs={12}>
                  <h2>Pre Assessment</h2>
                </Col>
                <Col xs={12} md={6}>
                  <Checkbox
                    disabled={isViewMode}
                    label={'Participants must join a team'}
                    value={settings.isRequiredJoinTeam}
                    onChange={(e) =>
                      setSettings((prev) => ({
                        ...prev,
                        isRequiredJoinTeam: e.value
                      }))
                    }
                  />
                </Col>
              </Row>

              <Row className={'mt-4 gy-2'}>
                <Col xs={12}>
                  <h2>Settings</h2>
                </Col>
                <Col xs={12} md={6}>
                  <Checkbox
                    disabled={isViewMode}
                    label={'Show purpose statement'}
                    value={settings.showPurposeStatement}
                    onChange={(e) =>
                      setSettings((prev) => ({
                        ...prev,
                        showPurposeStatement: e.value
                      }))
                    }
                  />
                </Col>
                <Col xs={12} md={6}>
                  <Checkbox
                    disabled={isViewMode}
                    label={'Show participant initials on actions'}
                    value={settings.showParticipantInitials}
                    onChange={(e) =>
                      setSettings((prev) => ({
                        ...prev,
                        showParticipantInitials: e.value
                      }))
                    }
                  />
                </Col>
              </Row>

              <Row className={'mt-4 gy-2'}>
                <Col xs={12}>
                  <h2>Competencies step</h2>
                </Col>
                <Col xs={12} md={6}>
                  <Checkbox
                    disabled={isViewMode}
                    label={'Show competency description'}
                    value={
                      settings.competenciesStepSettings
                        .showCompetencyDescription
                    }
                    onChange={(e) =>
                      setSettings((prev) => ({
                        ...prev,
                        competenciesStepSettings: {
                          ...prev.competenciesStepSettings,
                          showCompetencyDescription: e.value
                        }
                      }))
                    }
                  />
                </Col>
              </Row>

              <Row className={'mt-4 gy-2'}>
                <Col xs={12}>
                  <h2>Actions step</h2>
                </Col>
                <Col xs={12} md={6}>
                  <Checkbox
                    disabled={isViewMode}
                    label={'Show competency description'}
                    value={
                      settings.actionsStepSettings.showCompetencyDescription
                    }
                    onChange={(e) =>
                      setSettings((prev) => ({
                        ...prev,
                        actionsStepSettings: {
                          ...prev.actionsStepSettings,
                          showCompetencyDescription: e.value
                        }
                      }))
                    }
                  />
                </Col>
                <Col xs={12} md={6}>
                  <Checkbox
                    disabled={isViewMode}
                    label={'Show competency score'}
                    value={settings.actionsStepSettings.showCompetencyScore}
                    onChange={(e) =>
                      setSettings((prev) => ({
                        ...prev,
                        actionsStepSettings: {
                          ...prev.actionsStepSettings,
                          showCompetencyScore: e.value
                        }
                      }))
                    }
                  />
                </Col>
                <Col xs={12} md={6}>
                  <Checkbox
                    disabled={isViewMode}
                    label={
                      'Show actions to all participants as they are submitted'
                    }
                    value={settings.showActionsToAllParticipants}
                    onChange={(e) =>
                      setSettings((prev) => ({
                        ...prev,
                        showActionsToAllParticipants: e.value
                      }))
                    }
                  />
                </Col>
              </Row>

              <Row className={'mt-4 gy-2'}>
                <Col xs={12}>
                  <h2>Summary step</h2>
                </Col>
                <Col xs={12} md={6}>
                  <Checkbox
                    disabled={isViewMode}
                    label={'Show competency description'}
                    value={
                      settings.summaryStepSettings.showCompetencyDescription
                    }
                    onChange={(e) =>
                      setSettings((prev) => ({
                        ...prev,
                        summaryStepSettings: {
                          ...prev.summaryStepSettings,
                          showCompetencyDescription: e.value
                        }
                      }))
                    }
                  />
                </Col>
                <Col xs={12} md={6}>
                  <Checkbox
                    disabled={isViewMode}
                    label={'Show competency score'}
                    value={settings.summaryStepSettings.showCompetencyScore}
                    onChange={(e) =>
                      setSettings((prev) => ({
                        ...prev,
                        summaryStepSettings: {
                          ...prev.summaryStepSettings,
                          showCompetencyScore: e.value
                        }
                      }))
                    }
                  />
                </Col>
                <Col xs={12} md={6}>
                  <Checkbox
                    disabled={isViewMode}
                    label={'Show competencies without actions'}
                    value={
                      settings.summaryStepSettings
                        .showCompetenciesWithoutActions
                    }
                    onChange={(e) =>
                      setSettings((prev) => ({
                        ...prev,
                        summaryStepSettings: {
                          ...prev.summaryStepSettings,
                          showCompetenciesWithoutActions: e.value
                        }
                      }))
                    }
                  />
                </Col>
              </Row>

              <Row className={'mt-4 gy-2'}>
                <Col xs={12}>
                  <h2>Vote step</h2>
                </Col>
                <Col xs={12} md={6}>
                  <Checkbox
                    disabled={isViewMode}
                    label={'Show competency description'}
                    value={settings.voteStepSettings.showCompetencyDescription}
                    onChange={(e) =>
                      setSettings((prev) => ({
                        ...prev,
                        voteStepSettings: {
                          ...prev.voteStepSettings,
                          showCompetencyDescription: e.value
                        }
                      }))
                    }
                  />
                </Col>
                <Col xs={12} md={6}>
                  <Checkbox
                    disabled={isViewMode}
                    label={'Show competency score'}
                    value={settings.voteStepSettings.showCompetencyScore}
                    onChange={(e) =>
                      setSettings((prev) => ({
                        ...prev,
                        voteStepSettings: {
                          ...prev.voteStepSettings,
                          showCompetencyScore: e.value
                        }
                      }))
                    }
                  />
                </Col>
                <Col xs={12} md={6}>
                  <Checkbox
                    disabled={isViewMode}
                    label={'Show competencies without actions'}
                    value={
                      settings.voteStepSettings.showCompetenciesWithoutActions
                    }
                    onChange={(e) =>
                      setSettings((prev) => ({
                        ...prev,
                        voteStepSettings: {
                          ...prev.voteStepSettings,
                          showCompetenciesWithoutActions: e.value
                        }
                      }))
                    }
                  />
                </Col>
              </Row>

              <Row className={'mt-4 gy-2'}>
                <Col xs={12}>
                  <h2>Action plan step</h2>
                </Col>
                <Col xs={12} md={6}>
                  <Checkbox
                    disabled={isViewMode}
                    label={'Show competency description'}
                    value={
                      settings.actionPlanStepSettings.showCompetencyDescription
                    }
                    onChange={(e) =>
                      setSettings((prev) => ({
                        ...prev,
                        actionPlanStepSettings: {
                          ...prev.actionPlanStepSettings,
                          showCompetencyDescription: e.value
                        }
                      }))
                    }
                  />
                </Col>
                <Col xs={12} md={6}>
                  <Checkbox
                    disabled={isViewMode}
                    label={'Show competency score'}
                    value={settings.actionPlanStepSettings.showCompetencyScore}
                    onChange={(e) =>
                      setSettings((prev) => ({
                        ...prev,
                        actionPlanStepSettings: {
                          ...prev.actionPlanStepSettings,
                          showCompetencyScore: e.value
                        }
                      }))
                    }
                  />
                </Col>
                <Col xs={12} md={6}>
                  <Checkbox
                    disabled={isViewMode}
                    label={'Show competencies without actions'}
                    value={
                      settings.actionPlanStepSettings
                        .showCompetenciesWithoutActions
                    }
                    onChange={(e) =>
                      setSettings((prev) => ({
                        ...prev,
                        actionPlanStepSettings: {
                          ...prev.actionPlanStepSettings,
                          showCompetenciesWithoutActions: e.value
                        }
                      }))
                    }
                  />
                </Col>
                <Col xs={12} md={6}>
                  <Checkbox
                    disabled={isViewMode}
                    label={'Show due date'}
                    value={settings.showDueDate}
                    onChange={(e) =>
                      setSettings((prev) => ({ ...prev, showDueDate: e.value }))
                    }
                  />
                </Col>
                <Col xs={12} md={6}>
                  <Checkbox
                    disabled={isViewMode}
                    label={'Show assignee'}
                    value={settings.showAssignee}
                    onChange={(e) =>
                      setSettings((prev) => ({
                        ...prev,
                        showAssignee: e.value
                      }))
                    }
                  />
                </Col>
              </Row>

              <Row className={'mt-4 gy-2'}>
                <Col xs={12}>
                  <h2>Measurable step</h2>
                </Col>
                <Col xs={12} md={6}>
                  <Checkbox
                    disabled={isViewMode}
                    label={'Show measurable step to participants'}
                    value={settings.showMeasurableStep}
                    onChange={(e) =>
                      setSettings((prev) => ({
                        ...prev,
                        showMeasurableStep: e.value
                      }))
                    }
                  />
                </Col>
                <Col xs={12} md={6}>
                  <Checkbox
                    disabled={isViewMode}
                    label={'Show competency description'}
                    value={
                      settings.measurableStepSettings.showCompetencyDescription
                    }
                    onChange={(e) =>
                      setSettings((prev) => ({
                        ...prev,
                        measurableStepSettings: {
                          ...prev.measurableStepSettings,
                          showCompetencyDescription: e.value
                        }
                      }))
                    }
                  />
                </Col>
                <Col xs={12} md={6}>
                  <Checkbox
                    disabled={isViewMode}
                    label={'Show competency score'}
                    value={settings.measurableStepSettings.showCompetencyScore}
                    onChange={(e) =>
                      setSettings((prev) => ({
                        ...prev,
                        measurableStepSettings: {
                          ...prev.measurableStepSettings,
                          showCompetencyScore: e.value
                        }
                      }))
                    }
                  />
                </Col>
                <Col xs={12} md={6}>
                  <Checkbox
                    disabled={isViewMode}
                    label={'Show competencies without actions'}
                    value={
                      settings.measurableStepSettings
                        .showCompetenciesWithoutActions
                    }
                    onChange={(e) =>
                      setSettings((prev) => ({
                        ...prev,
                        measurableStepSettings: {
                          ...prev.measurableStepSettings,
                          showCompetenciesWithoutActions: e.value
                        }
                      }))
                    }
                  />
                </Col>
              </Row>

              <Row className={'mt-4 gy-2'}>
                <Col xs={12}>
                  <h2>Result step</h2>
                </Col>
                <Col xs={12} md={6}>
                  <Checkbox
                    disabled={isViewMode}
                    label={'Show results step to participants'}
                    value={settings.showResultsStep}
                    onChange={(e) =>
                      setSettings((prev) => ({
                        ...prev,
                        showResultsStep: e.value
                      }))
                    }
                  />
                </Col>
                <Col xs={12} md={6}>
                  <Checkbox
                    disabled={isViewMode}
                    label={
                      "Show 'Download report' on results step to participants"
                    }
                    value={settings.showDownloadReport}
                    onChange={(e) =>
                      setSettings((prev) => ({
                        ...prev,
                        showDownloadReport: e.value
                      }))
                    }
                  />
                </Col>
              </Row>

              {/* <div className={'gap-2'}>
                <Row>
                  <Col sm={'12'}>
                    <Checkbox
                    disabled={isViewMode}
                      className={'text-primary'}
                      name={'enableDueDateOnActionPlan'}
                      label={"Enable the 'Due Date' for the Action Plan."}
                      size={'medium'}
                      value={entity?.settings?.enableDueDateOnActionPlan}
                      onChange={(e) => {
                        onChangeSettings(
                          e.target.value as boolean,
                          e.target.name! as keyof AssessmentSettings
                        );
                      }}
                      disabled={isViewMode}
                    />
                  </Col>
                  <Col sm={'12'}>
                    <Checkbox
                    disabled={isViewMode}
                      className={'text-primary'}
                      name={'enableAssignToOnActionPlan'}
                      label={"Enable the 'Responsibility' for the Action Plan."}
                      size={'medium'}
                      value={entity?.settings?.enableAssignToOnActionPlan}
                      onChange={(e) => {
                        onChangeSettings(
                          e.target.value as boolean,
                          e.target.name! as keyof AssessmentSettings
                        );
                      }}
                      disabled={isViewMode}
                    />
                  </Col>
                  <Col sm={'12'}>
                    <Checkbox
                    disabled={isViewMode}
                      className={'text-primary'}
                      name={'holdActionFromParticipant'}
                      label={
                        'Hold the action from the participant until the next stage.'
                      }
                      size={'medium'}
                      value={entity?.settings?.holdActionFromParticipant}
                      onChange={(e) => {
                        onChangeSettings(
                          e.target.value as boolean,
                          e.target.name! as keyof AssessmentSettings
                        );
                      }}
                      disabled={isViewMode}
                    />
                  </Col>
                  <Col sm={'12'}>
                    <Checkbox
                    disabled={isViewMode}
                      className={'text-primary'}
                      name={'showPurposeStatement'}
                      label={'Show purpose statement.'}
                      size={'medium'}
                      value={entity?.settings?.showPurposeStatement}
                      onChange={(e) => {
                        onChangeSettings(
                          e.target.value as boolean,
                          e.target.name! as keyof AssessmentSettings
                        );
                      }}
                      disabled={isViewMode}
                    />
                  </Col>
                  <Col sm={'12'}>
                    <Checkbox
                    disabled={isViewMode}
                      className={'text-primary'}
                      name={'enableSuccessMeasurables'}
                      label={'Enable success measurables.'}
                      size={'medium'}
                      value={entity?.settings?.enableSuccessMeasurables}
                      onChange={(e) => {
                        onChangeSettings(
                          e.target.value as boolean,
                          e.target.name! as keyof AssessmentSettings
                        );
                      }}
                      disabled={isViewMode}
                    />
                  </Col>
                  <Col sm={'12'}>
                    <Checkbox
                    disabled={isViewMode}
                      className={'text-primary'}
                      name={'hideDownloadReportFromParticipant'}
                      label={
                        'Hide download report from participant end screen.'
                      }
                      size={'medium'}
                      value={
                        entity?.settings?.hideDownloadReportFromParticipant
                      }
                      onChange={(e) => {
                        onChangeSettings(
                          e.target.value as boolean,
                          e.target.name! as keyof AssessmentSettings
                        );
                      }}
                      disabled={isViewMode}
                    />
                  </Col>
                  <Col sm={'12'}>
                    <Checkbox
                    disabled={isViewMode}
                      className={'text-primary'}
                      name={'hideFinalResultsFromParticipant'}
                      label={'Hide final results on player screen.'}
                      size={'medium'}
                      value={entity?.settings?.hideFinalResultsFromParticipant}
                      onChange={(e) => {
                        onChangeSettings(
                          e.target.value as boolean,
                          e.target.name! as keyof AssessmentSettings
                        );
                      }}
                      disabled={isViewMode}
                    />
                  </Col>
                </Row>
              </div> */}
            </Tab>
          )}
        </Tabs>
      </Row>
      {showUploadLogo ? (
        <ResourceWindow
          toggleDialog={() => {
            setShowUploadLogo(false);
          }}
          onSubmit={(uploadedFile) => handleUploadLogo(uploadedFile)}
          acceptedExtension={'image/*'}
          imageUrl={''}
          onClose={() => {
            setShowUploadLogo(false);
          }}
          hideResourcesTab={true}
        />
      ) : null}
      {showDeleteLogo && (
        <YesNoDialog
          title={'Confirm logo removal'}
          onConfirm={handleDeleteLogo}
          onClose={() => setShowDeletedLogo(false)}>
          Are you sure you want to remove the logo?
        </YesNoDialog>
      )}
    </>
  );
};
