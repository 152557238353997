import { GameDocumentContext } from '../../../contexts/game-document';
import React, { useContext, useEffect, useState } from 'react';
import Toolbar from '../toolbar';
import {
  AddResourceAsync,
  AddTitleAsync,
  CopyTitleAsync,
  DeleteTitleAsync,
  ExportTitle,
  ImportTitlesAsync,
  UpdateGameDocState,
  UpdateResourceAsync,
  UpdateTitleAsync
} from '../../../utils/game-document';
import { Button } from '@progress/kendo-react-buttons';
import { process, State } from '@progress/kendo-data-query';
import {
  Grid,
  GridCellProps,
  GridColumn as Column,
  GridDataStateChangeEvent,
  GridNoRecords,
  GridSelectionChangeEvent
} from '@progress/kendo-react-grid';
import { LinkCell } from '../../../components/grid';
import { ResourceEntity, TitleEntity } from '../../../types/game-document/';
import { EntityEditor } from '../../../types/game-document/entity-editor';
import EditDeleteCloneCell from '../../../components/grid/edit-delete-clone-cell';
import {
  GridToolBar,
  GridToolbarDataStateChangeEvent
} from '../../../components/grid/grid-tool-bar';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { NoRecords } from '../../../components/grid/no-records';
import { DefaultGridSettings } from '../../../constants/grid-settings';
import { uuid } from '../../../types/common-helper';
import { useSessionStorage } from 'usehooks-ts';
import { getCurrentTimeStamp } from '../../../utils/date';
import { downloadJSON, handleFileUpload } from '../../../utils/files';
import { toastStore } from '../../../stores/toast-store';
import { ExportedTitleData } from '../../../types/game-document/entities/exported-title';
import { useNavigate } from 'react-router-dom';

const initialDataState: State = {
  sort: [{ field: 'name', dir: 'asc' }],
  ...DefaultGridSettings.initialDataState
};

export interface TitleResIds extends TitleEntity {
  titleResId?: string;
  summaryResId?: string;
  imageResId?: string;
  selected: boolean;
}

const SELECTED_FIELD = 'selected';
const Titles = () => {
  const [state, setState] = useContext(GameDocumentContext);
  const [dataState, setDataState] = useSessionStorage<State>(
    'datastate-titles',
    initialDataState
  );
  const [entityEditorValue, setEntityEditorValue] =
    useState<EntityEditor<TitleEntity>>();
  const [resourceEditorValue, setResourceEditorValue] =
    useState<EntityEditor<ResourceEntity>[]>();
  const [entityEditorIsVisible, setEntityEditorIsVisible] =
    useState<boolean>(false);
  const toggleEntityEditor = () => {
    setEntityEditorIsVisible(!entityEditorIsVisible);
  };

  const [titles, setTitles] = useState<TitleResIds[]>([]);
  const [selectedTitleIds, setSelectedTitleIds] = useState<string[]>([]);
  const navigate = useNavigate();

  const titleGuid = uuid();
  const summaryGuid = uuid();
  const imageGuid = uuid();

  useEffect(() => {
    // Set the page title.
    document.title = `Titles - ${state.gameDocument?.name}`;
    setTitles(state.gameDocument?.assets.titles as TitleResIds[]);
  }, [state]);

  const LinkedNameCell = (props: GridCellProps) => (
    <LinkCell to={props.dataItem['id'].toString()} {...props}></LinkCell>
  );

  const DeleteCell = (props: GridCellProps) => (
    <EditDeleteCloneCell
      onEditClick={() => navigate(`${props.dataItem['id']}`)}
      onCloneClick={() => onCloneEntity(props.dataItem['id'])}
      onDeleteClick={() => onDeleteEntity(props.dataItem['id'])}
      {...props}
    />
  );

  const handleJsonExport = async () => {
    if (selectedTitleIds.length === 0) {
      toastStore.show(
        'Error',
        'Please select the title you want to export first.',
        'error'
      );
      return;
    }
    const exportedTaskContents = ExportTitle(
      state.gameDocument!,
      selectedTitleIds
    );
    const formattedTimestamp = getCurrentTimeStamp();

    downloadJSON(exportedTaskContents, `title-${formattedTimestamp}.json`);
  };

  const handleJsonImport = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    handleFileUpload<ExportedTitleData>(
      event,
      async (exportedTitleData) => {
        ImportTitlesAsync(state.gameDocument!, exportedTitleData).then(
          (updatedGameDocument) => {
            setState((state) => UpdateGameDocState(state, updatedGameDocument));
            toastStore.show('Info', 'Import Success', 'info');
          }
        );
      },
      (message) => {
        toastStore.show('Error', message, 'error');
      }
    );
  };

  const handleEntityEditorSubmit = (
    editorEntity: EntityEditor<TitleEntity>,
    resourceEntity: EntityEditor<ResourceEntity>[]
  ) => {
    if (editorEntity.isNew) {
      const addTitleResource = AddResourceAsync(
        state.gameDocument!,
        'Title',
        '',
        'text',
        resourceEntity.find(
          (title) => title.entity.id === editorEntity.entity.titleResId!
        )?.entity.value!,
        titleGuid
      );
      const addSummaryResource = AddResourceAsync(
        state.gameDocument!,
        'Summary',
        '',
        'text',
        resourceEntity.find(
          (title) => title.entity.id === editorEntity.entity.summaryResId!
        )?.entity.value!,
        summaryGuid
      );

      const imageResource = resourceEntity.find(
        (item) => item.entity.id === editorEntity.entity.imageResId!
      )?.entity;
      const addImageResource = AddResourceAsync(
        state.gameDocument!,
        imageGuid,
        imageResource?.description ?? '',
        'image',
        imageResource?.value ?? '',
        imageGuid,
        imageResource?.size ?? 0
      );
      Promise.all([
        addTitleResource,
        addSummaryResource,
        addImageResource
      ]).then(() => {
        const {
          entity: { name, description }
        } = editorEntity;
        AddTitleAsync(
          state.gameDocument!,
          name,
          description,
          titleGuid,
          summaryGuid,
          imageGuid,
          editorEntity.entity.hideInGame
        ).then((updatedGameDocument) => {
          setState((prev) => UpdateGameDocState(prev, updatedGameDocument));
          setEntityEditorIsVisible(false);
        });
      });
    } else {
      const titleResource = resourceEntity.find(
        (resource) => resource.entity.id === editorEntity.entity.titleResId
      );
      const summaryResource = resourceEntity.find(
        (resource) => resource.entity.id === editorEntity.entity.summaryResId
      );
      const imageResource = resourceEntity.find(
        (resource) => resource.entity.id === editorEntity.entity.imageResId
      );
      const updateTitleResource = UpdateResourceAsync(
        state.gameDocument!,
        editorEntity.entity.titleResId!,
        titleResource?.entity!
      );
      const updateSummaryResource = UpdateResourceAsync(
        state.gameDocument!,
        editorEntity.entity.summaryResId!,
        summaryResource?.entity!
      );
      const updateImageResource = UpdateResourceAsync(
        state.gameDocument!,
        editorEntity.entity.imageResId!,
        imageResource?.entity!
      );
      Promise.all([
        updateTitleResource,
        updateSummaryResource,
        updateImageResource
      ]).then(() => {
        UpdateTitleAsync(
          state.gameDocument!,
          editorEntity.entity.id,
          editorEntity.entity
        ).then((updatedGameDocument) => {
          setState((prev) => UpdateGameDocState(prev, updatedGameDocument));
          setEntityEditorIsVisible(false);
        });
      });
    }
  };

  const onCloneEntity = (entityId: string) => {
    CopyTitleAsync(state.gameDocument!, entityId).then(
      (updatedGameDocument) => {
        setState((prev) => UpdateGameDocState(prev, updatedGameDocument));
      }
    );
  };

  const onDeleteEntity = async (entityId: string) => {
    DeleteTitleAsync(state.gameDocument!, entityId).then(
      (updatedGameDocument) => {
        setState((prev) => UpdateGameDocState(prev, updatedGameDocument));
      }
    );
  };

  const onSelectionChange = (event: GridSelectionChangeEvent) => {
    const selectedTitleId = event.dataItem['id'];

    const updatedTitles = titles?.map((item) =>
      item.id === selectedTitleId ? { ...item, selected: !item.selected } : item
    );
    setTitles(updatedTitles);

    const taskContent = updatedTitles?.find(
      (item) => item.id === selectedTitleId
    );

    if (taskContent) {
      const updatedSelectedTaskContentIds = taskContent.selected
        ? [...selectedTitleIds, selectedTitleId]
        : selectedTitleIds.filter((id) => id !== selectedTitleId);

      setSelectedTitleIds(updatedSelectedTaskContentIds);
    }
  };

  const onHeaderSelectionChange = () => {
    const updatedTitles = titles?.map((item) => ({
      ...item,
      selected: !item.selected
    }));

    const updatedSelectedTitleIds = updatedTitles?.reduce<string[]>(
      (ids, item) => {
        if (item.selected) {
          return [...ids, item.id];
        } else {
          return ids.filter((id) => id !== item.id);
        }
      },
      [...(selectedTitleIds || [])]
    );

    setSelectedTitleIds(updatedSelectedTitleIds || []);
    setTitles(updatedTitles);
  };

  const gridExportRef = React.useRef<ExcelExport | null>(null);

  return (
    <>
      <Toolbar
        title={'Titles'}
        buttonHelpSupport={{
          title: 'Titles guide',
          url: 'https://forum.catalystglobal.com/t/4594'
        }}>
        <Button onClick={() => navigate('new')} themeColor={'primary'}>
          Add title
        </Button>
      </Toolbar>
      <div className={'pt-2'}>
        <GridToolBar
          searchPlaceholder={'Search titles'}
          columnsToSearch={['name', 'description']}
          showCardMode={false}
          exportRef={gridExportRef}
          {...dataState}
          onDataStateChange={(e: GridToolbarDataStateChangeEvent) => {
            setDataState(e.dataState);
          }}
          showJsonExport={true}
          handleJsonExport={handleJsonExport}
          showJsonImport={true}
          handleJsonImport={handleJsonImport}
        />
        <ExcelExport
          data={state.gameDocument?.assets.titles ?? []}
          ref={gridExportRef}
          fileName={`${state.gameDocument?.name} titles.xlsx`}>
          <Grid
            pageable={DefaultGridSettings.pagerSettings}
            sortable={true}
            className={'cg-grid3'}
            data={process(titles, dataState)}
            selectedField={SELECTED_FIELD}
            {...dataState}
            onDataStateChange={(e: GridDataStateChangeEvent) => {
              setDataState(e.dataState);
            }}
            onSelectionChange={onSelectionChange}
            onHeaderSelectionChange={onHeaderSelectionChange}>
            <Column filterable={false} field={SELECTED_FIELD} width={50} />
            <Column field={'name'} title={'Name'} cell={LinkedNameCell} />
            <Column field={'description'} title={'Description'} />
            <Column cell={DeleteCell} />
            <GridNoRecords>
              <NoRecords />
            </GridNoRecords>
          </Grid>
        </ExcelExport>
      </div>
    </>
  );
};

export default Titles;
