import { useAuth } from 'react-oidc-context';
import { Navigate, Outlet } from 'react-router-dom';
import { Permission } from '../types/permission';

interface ProtectedProps {
  allowedRoles: string[];
}

const getPermissionKey = (value: string) => {
  const indexOfS = Object.values(Permission).indexOf(
    value as unknown as Permission
  );

  const key = Object.keys(Permission)[indexOfS];

  return key;
};

const Protected = ({ allowedRoles }: ProtectedProps) => {
  const auth = useAuth();
  const roles = allowedRoles?.map((r) => getPermissionKey(r!));
  const isInRole = (auth.user?.profile.role as string[]).some((role) =>
    roles?.includes(role)
  );

  if (!isInRole) {
    return <Navigate to={'/'} replace />;
  }

  return <Outlet />;
};

export default Protected;
