import { GameDocumentContext } from '../../../contexts/game-document';
import React, { useContext, useEffect, useState } from 'react';
import Toolbar from '../toolbar';
import {
  DeleteZoneAsync,
  UpdateGameDocState,
  UpdateZoneAsync,
  UpdateResourceAsync
} from '../../../utils/game-document';
import { Button } from '@progress/kendo-react-buttons';
import { useNavigate, useParams } from 'react-router-dom';
import {
  CheckboxChangeEvent,
  InputChangeEvent
} from '@progress/kendo-react-inputs';
import { EntityEditor } from '../../../types/game-document/entity-editor';
import { ResourceEntity, ZoneEntity } from '../../../types/game-document/';
import { FluidForm } from '../../../components/forms';
import {
  ZoneEditor,
  ZoneEditorWindow
} from '../../../features/game-document/zones';
import { Col, Row } from 'react-bootstrap';
import { ActionTopRow } from '../../../components/game-designer/action-top-row';

const Zone = () => {
  const { zoneId } = useParams();
  const [state, setState] = useContext(GameDocumentContext);
  const navigate = useNavigate();

  const getEntityById = (entityId: string) =>
    state.gameDocument?.assets.zones?.find((i) => i.id === entityId)!;

  const [entity, setEntity] = useState<ZoneEntity>(() =>
    getEntityById(zoneId!)
  );
  const [zoneResource, setZoneResource] = useState<ResourceEntity>();
  const [resources, setResources] = useState<ResourceEntity[]>([]);

  useEffect(() => {
    setEntity(getEntityById(zoneId!));
  }, [state]);

  useEffect(() => {
    // Set the page title.
    document.title = `${entity.name} - ${state.gameDocument?.name}`;

    if (entity) {
      const zoneRes = state.gameDocument?.resources.find(
        (item) => item.id === entity.titleResId!
      )! as ResourceEntity;
      if (zoneRes !== undefined) {
        setZoneResource(zoneRes);
        setResources([zoneRes]);
      }
    }
  }, [state, entity]);

  const handleInputChange = (event: InputChangeEvent | CheckboxChangeEvent) => {
    const { name, value } = event.target;

    if (name === 'titleResId') {
      setResources(
        resources.map((item) =>
          item.id === entity[name]
            ? { ...item, value: value! as string }
            : { ...item }
        )
      );
      const resource = resources.find(
        (item) => item.id === entity?.titleResId!
      )!;
      resource.value = value as string;

      UpdateResourceAsync(state.gameDocument!, resource?.id, resource).then(
        (updatedGameDocument) => {
          setState((prev) => UpdateGameDocState(prev, updatedGameDocument));
        }
      );
    } else {
      setEntity((prev) => ({
        ...prev,
        [name!]: value
      }));
      UpdateZoneAsync(state.gameDocument!, zoneId!, {
        ...entity,
        [name!]: value
      }).then((updatedGameDocument) => {
        setState((prev) => UpdateGameDocState(prev, updatedGameDocument));
      });
    }
  };

  const [entityEditorIsVisible, setEntityEditorIsVisible] =
    useState<boolean>(false);
  const toggleEntityEditor = () => {
    setEntityEditorIsVisible(!entityEditorIsVisible);
  };

  const handleEntityEditorSubmit = (
    editorEntity: EntityEditor<ZoneEntity>,
    resourceEntity: EntityEditor<ResourceEntity>[]
  ) => {
    const zoneResource = resourceEntity.find(
      (resource) => resource.entity.id === editorEntity.entity.titleResId
    );

    const updateZoneResource = UpdateResourceAsync(
      state.gameDocument!,
      editorEntity.entity.titleResId!,
      zoneResource?.entity!
    );

    Promise.all([updateZoneResource]).then(() => {
      UpdateZoneAsync(
        state.gameDocument!,
        editorEntity.entity.id,
        editorEntity.entity
      ).then((updatedGameDocument) => {
        setState((prev) => UpdateGameDocState(prev, updatedGameDocument));
        setEntityEditorIsVisible(false);
      });
    });
  };

  const onDeleteEntity = async (entityId: string) => {
    DeleteZoneAsync(state.gameDocument!, entityId).then(
      (updatedGameDocument) => {
        setState((prev) => UpdateGameDocState(prev, updatedGameDocument));
        navigate('../');
      }
    );
  };

  const onViewEntity = (entityId: string) => {
    navigate(`../../map/${entityId}`);
  };

  return (
    <>
      <Toolbar title={entity?.name} />
      <ActionTopRow
        onViewClick={() => {
          onViewEntity(entity.id!);
        }}
        onDeleteClick={() => {
          onDeleteEntity(entity.id!);
        }}
      />
      <FluidForm>
        <ZoneEditor
          editorMode={'full'}
          entity={entity}
          resources={resources}
          handleInputChange={handleInputChange}
        />
      </FluidForm>
    </>
  );
};

export default Zone;
